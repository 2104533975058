import {Button, FormGroup, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {Redirect, RouteComponentProps} from 'react-router';
import {FieldMappingTypes} from '../../../Api/Objects/Feed';
import {IFeedMappingTemplate, TemplateFieldTypes} from '../../../Api/Objects/FeedMappingTemplate';
import {Projection} from '../../../Api/Projection';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../../Contexts/ToasterContext';
import {LinkButton} from '../../Navigation/LinkButton';
import {FieldMappingEditor} from '../Feeds/FieldMappingEditor';
import {RequiredFieldsNotice} from '../Feeds/RequiredFieldsNotice';

interface IRouteProps {
	template: string;
}

interface ITempateEditorProps extends RouteComponentProps<IRouteProps>, IApiClientAware, IToasterAware {
}

interface ITemplateEditorState {
	headers: string[];
	headersController: AbortController;
	headersLoading: boolean;
	loading: boolean;
	mappings: FieldMappingTypes[];
	name: string;
	omitMappingFields: string[];
	redirect: boolean;
	sampleFeedUrl: string;
	saving: boolean;
}

class TemplateEditorComponent extends React.PureComponent<ITempateEditorProps, ITemplateEditorState> {
	public state: Readonly<ITemplateEditorState> = {
		headers: [],
		headersController: null,
		headersLoading: false,
		loading: true,
		mappings: [],
		name: '',
		omitMappingFields: [],
		redirect: false,
		sampleFeedUrl: '',
		saving: false,
	};

	public componentDidMount(): void {
		const idParam = this.props.match.params.template;

		if (idParam === 'new') {
			this.setState({
				loading: false,
			});

			return;
		}

		this.props.client.templates.get(parseInt(idParam, 10)).then(template => this.setState({
			loading: false,
			mappings: template.fields.map(this.toFieldMapping),
			name: template.name,
			omitMappingFields: template.fields.map(field => field.targetField),
		}));
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;
		else if (this.state.redirect)
			return <Redirect to="/edit/templates" />;

		const idParam = this.props.match.params.template;
		const excludeTemplates = [];

		if (idParam !== 'new')
			excludeTemplates.push(idParam);

		return (
			<>
				<H2>{this.state.name.length ? this.state.name : <em>No Name</em>}</H2>

				<form onSubmit={this.onSave}>
					<Row>
						<Cell size={6}>
							<FormGroup label="Name" labelFor="name">
								<InputGroup name="name" onChange={this.onNameChange} value={this.state.name} />
							</FormGroup>
						</Cell>

						<Cell size={6}>
							<FormGroup
								label="Sample Feed"
								labelFor="sampleFeedUrl"
								helperText={`
									Can be used to provide a list of headers to autofill fields. This value is not
									saved with the template.
								`}
							>
								<InputGroup
									name="sampleFeedUrl"
									onChange={this.onSampleFeedUrlChange}
									rightElement={this.state.headersLoading && (
										<div style={{marginTop: 3, marginRight: 3}}>
											<Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_SMALL} />
										</div>
									)}
									value={this.state.sampleFeedUrl}
								/>
							</FormGroup>
						</Cell>
					</Row>

					<FieldMappingEditor
						excludeTemplates={excludeTemplates}
						headers={this.state.headers}
						headersLoading={this.state.headersLoading}
						mappings={this.state.mappings}
						omitMappingFields={this.state.omitMappingFields}
						onMappingAdd={this.onMappingAdd}
						onMappingEdit={this.onMappingEdit}
						onMappingRemove={this.onMappingRemove}
					/>

					<RequiredFieldsNotice fields={this.state.omitMappingFields} />

					<Row align="end">
						<Cell size={1}>
							<LinkButton
								buttonProps={{
									fill: true,
									loading: this.state.saving,
								}}
								to="/edit/templates"
							>
								Cancel
							</LinkButton>
						</Cell>

						<Cell size={1}>
							<Button
								fill={true}
								intent={Intent.PRIMARY}
								loading={this.state.saving}
								onClick={this.onSave}
							>
								Save
							</Button>
						</Cell>
					</Row>
				</form>
			</>
		);
	}

	private loadFeedHeaders = () => {
		if (this.state.headersController)
			this.state.headersController.abort();

		if (this.state.sampleFeedUrl.length === 0) {
			this.setState({
				headers: [],
			});

			return;
		}

		const controller = new AbortController();

		this.setState({
			headersController: controller,
			headersLoading: true,
		});

		this.props.client.feeds.headers(this.state.sampleFeedUrl, controller.signal).then(headers => this.setState({
			headers: headers.sort(),
			headersLoading: false,
		})).catch((error: Error) => {
			this.props.toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				headersController: null,
				headersLoading: false,
			});
		});
	};

	private onMappingAdd = (mapping: FieldMappingTypes) => {
		this.setState({
			mappings: [...this.state.mappings, mapping],
			omitMappingFields: [...this.state.omitMappingFields, mapping.targetField],
		});
	};

	private onMappingEdit = () => this.setState({
		mappings: [...this.state.mappings],
		omitMappingFields: this.state.mappings.map(mapping => mapping.targetField),
	});

	private onMappingRemove = (mapping: FieldMappingTypes) => this.setState({
		mappings: this.state.mappings.filter(item => item !== mapping),
		omitMappingFields: this.state.omitMappingFields.filter(item => item !== mapping.targetField),
	});

	private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		name: event.currentTarget.value,
	});

	private onSampleFeedUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		sampleFeedUrl: event.currentTarget.value,
	}, () => this.loadFeedHeaders());

	private onSave = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		if (this.state.saving)
			return;

		this.setState({
			saving: true,
		});

		const payload: IFeedMappingTemplate = {
			fields: this.state.mappings.map(this.toTemplateField),
			name: this.state.name,
		};

		const projection: Projection = {
			name: true,
		};

		const idParam = this.props.match.params.template;
		let promise: Promise<IFeedMappingTemplate>;

		if (idParam === 'new')
			promise = this.props.client.templates.create(payload, projection);
		else
			promise = this.props.client.templates.update(parseInt(idParam, 10), payload, projection);

		promise.then(template => {
			this.props.toaster.show({
				intent: Intent.SUCCESS,
				message: `${template.name} ${idParam === 'new' ? 'created' : 'updated'} successfully.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			this.props.toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				loading: false,
			});
		});
	};

	private toFieldMapping = (field: TemplateFieldTypes): FieldMappingTypes => ({
		...field.arguments,
		targetField: field.targetField,
		translations: field.translations,
		type: field.type,
	}) as FieldMappingTypes;

	private toTemplateField = ({targetField, translations, type, ...args}: FieldMappingTypes) => ({
		arguments: args,
		targetField,
		translations,
		type,
	}) as TemplateFieldTypes;
}

export const TemplateEditor = withToaster(withApiClient(TemplateEditorComponent));
