import {ApiClient} from '../ApiClient';
import {AbstractApiClientModule} from '../Module';
import {IEntity} from './Entity';

export interface IFacebookAccount extends IEntity {
	id: string;
	name: string;
}

export class FacebookAccountApiClientModule extends AbstractApiClientModule<IFacebookAccount> {
	public constructor(client: ApiClient) {
		super(client, '/facebook/accounts');
	}
}
