export enum FeedField {
	ADDRESS = 'address',
	AVAILABILITY = 'availability',
	BODY_STYLE = 'body_style',
	CONDITION = 'state_of_vehicle',
	CURRENCY = 'currency',
	CUSTOM_LABEL_0 = 'custom_label_0',
	DATE_ON_LOT = 'date_on_lot',
	DESCRIPTION = 'description',
	DRIVETRAIN = 'drivetrain',
	EXTERIOR_COLOR = 'exterior_color',
	FUEL_TYPE = 'fuel_type',
	PRIMARY_IMAGE = 'image',
	INTERIOR_COLOR = 'interior_color',
	LATITUDE = 'latitude',
	LONGITUDE = 'longitude',
	MAKE = 'make',
	MILEAGE = 'mileage',
	MODEL = 'model',
	PRICE = 'price',
	SALE_PRICE = 'sale_price',
	SECONDARY_IMAGES = 'images',
	TITLE = 'title',
	TRANSMISSION = 'transmission',
	TRIM = 'trim',
	URL = 'url',
	VEHICLE_ID = 'vehicle_id',
	VIN = 'vin',
	YEAR = 'year',
}

// @ts-ignore
export const feedFields = Object.keys(FeedField).map(key => FeedField[key]);

export const requiredFeedFields: string[] = [
	FeedField.ADDRESS,
	FeedField.BODY_STYLE,
	FeedField.CONDITION,
	FeedField.DESCRIPTION,
	FeedField.EXTERIOR_COLOR,
	FeedField.LATITUDE,
	FeedField.LONGITUDE,
	FeedField.MAKE,
	FeedField.MODEL,
	FeedField.MILEAGE,
	FeedField.PRICE,
	FeedField.PRIMARY_IMAGE,
	FeedField.TITLE,
	FeedField.TRANSMISSION,
	FeedField.URL,
	FeedField.VEHICLE_ID,
	FeedField.VIN,
	FeedField.YEAR,
];

export const feedFieldLabels: {[key: string]: string} = {
	[FeedField.ADDRESS]: 'Address',
	[FeedField.AVAILABILITY]: 'Availability',
	[FeedField.BODY_STYLE]: 'Body Style',
	[FeedField.CONDITION]: 'Condition',
	[FeedField.CURRENCY]: 'Currency',
	[FeedField.CUSTOM_LABEL_0]: 'Custom Label 0',
	[FeedField.DATE_ON_LOT]: 'Date On Lot',
	[FeedField.DESCRIPTION]: 'Description',
	[FeedField.DRIVETRAIN]: 'Drivetrain',
	[FeedField.EXTERIOR_COLOR]: 'Exterior Color',
	[FeedField.FUEL_TYPE]: 'Fuel Type',
	[FeedField.PRIMARY_IMAGE]: 'Primary Image',
	[FeedField.INTERIOR_COLOR]: 'Interior Color',
	[FeedField.LATITUDE]: 'Latitude',
	[FeedField.LONGITUDE]: 'Longitude',
	[FeedField.MAKE]: 'Make',
	[FeedField.MILEAGE]: 'Mileage',
	[FeedField.MODEL]: 'Model',
	[FeedField.SALE_PRICE]: 'Sale Price',
	[FeedField.SECONDARY_IMAGES]: 'Secondary Images',
	[FeedField.PRICE]: 'Price',
	[FeedField.TITLE]: 'Title',
	[FeedField.TRANSMISSION]: 'Transmission',
	[FeedField.TRIM]: 'Trim',
	[FeedField.URL]: 'URL',
	[FeedField.VEHICLE_ID]: 'Vehicle ID',
	[FeedField.VIN]: 'VIN',
	[FeedField.YEAR]: 'Year',
};
