import {Button, Menu, MenuItem, Popover} from '@blueprintjs/core';
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {IFeed} from '../../../Api/Objects/Feed';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {LinkButton} from '../../Navigation/LinkButton';
import {BreadcrumbItems, EditorBreadcrumbs} from '../EditorBreadcrumbs';
import {createEntityFilter, createEntitySorter, EntityList} from '../EntityList';

type Feed = Pick<IFeed, 'id' | 'createdDate' | 'name'>;

const sorter = createEntitySorter<Feed>('name');
const filterEntityOnName = createEntityFilter<Feed>('name');

interface IRouteProps {
	account: string;
	catalog: string;
}

interface IFeedListProps extends IApiClientAware, RouteComponentProps<IRouteProps> {
}

const FeedListComponent: React.FC<IFeedListProps> = props => {
	const {account, catalog} = props.match.params;

	return (
		<>
			<EditorBreadcrumbs
				items={[
					BreadcrumbItems.accounts(),
					BreadcrumbItems.catalogs(account),
					BreadcrumbItems.feeds(account, catalog),
				]}
			/>

			<EntityList
				basePath={`/edit/accounts/${account}/catalogs/${catalog}/feeds`}
				deleteToastMessage={target => `${target.name} has been deleted successfully.`}
				extraControls={(
					<LinkButton
						buttonProps={{icon: 'edit'}}
						to={`/edit/accounts/${account}/catalogs/${catalog}`}
					>
						Edit Catalog
					</LinkButton>
				)}
				projection={{
					createdDate: true,
					downloadKey: true,
					name: true,
				}}
				provider={props.client.feeds}
				query={{
					'catalog.id': props.match.params.catalog,
				}}
				rowControls={record => {
					const path = `${process.env.API_URL}/feeds/download/${record.downloadKey}`;

					return (
						<Popover
							content={(
								<Menu>
									<MenuItem text="As CSV" icon="th" target="_blank" href={`${path}.csv`} />
									<MenuItem text="As JSON" icon="code" target="_blank" href={`${path}.json`} />
								</Menu>
							)}
						>
							<Button minimal={true}>
								Download
							</Button>
						</Popover>
					);
				}}
				rowControlsWidth={250}
				sorter={sorter}
				tableColumns={[
					{
						dataIndex: 'name',
						onFilter: filterEntityOnName,
						title: 'Name',
					},
					{
						render: record => record.createdDate.toLocaleString(),
						title: 'Created Date',
					},
				]}
				tableNoDataPlaceholder={<span>No feeds found.</span>}
				title="Feeds"
			/>
		</>
	);
};

export const FeedList = withApiClient(withRouter(FeedListComponent));
