import {Intent} from '@blueprintjs/core';
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {IUser} from '../../../Api/Objects/User';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../../Contexts/ToasterContext';
import {createEntitySorter, EntityList} from '../EntityList';

type User = Pick<IUser, 'id' | 'archived' | 'createdDate' | 'firstName' | 'lastName'>;

const sorter = createEntitySorter<User>('firstName');
const filterEntityOnName = (record: User, search: string) => {
	return `${record.firstName} ${record.lastName}`.toLowerCase().indexOf(search) > -1;
};

interface IUserListProps extends RouteComponentProps<{}>, IApiClientAware, IToasterAware {
}

const UserListComponent: React.FC<IUserListProps> = props => (
	<EntityList
		basePath="/edit/users"
		deleteToastMessage={target => `${target.firstName} ${target.lastName} deleted successfully.`}
		onDelete={user => {
			if (user.email !== props.client.getToken().body.username)
				return;

			props.toaster.show({
				intent: Intent.PRIMARY,
				message: 'You have been logged out.',
			});

			props.client.logout();
			props.history.push('/');
		}}
		projection={{
			createdDate: true,
			email: true,
			firstName: true,
			lastName: true,
		}}
		provider={props.client.users}
		query={{
			archived: false,
		}}
		sorter={sorter}
		tableColumns={[
			{
				onFilter: filterEntityOnName,
				render: record => {
					return `${record.firstName} ${record.lastName}`.trim() + (record.archived ? '(Archived)' : '');
				},
				title: 'Name',
			},
			{
				render: record => record.createdDate.toLocaleString(),
				title: 'Created Date',
			},
		]}
		tableNoDataPlaceholder={<span>No users found.</span>}
		title="Users"
	/>
);

export const UserList = withToaster(withRouter(withApiClient(UserListComponent)));
