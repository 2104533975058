import {Omit} from 'utility-types';
import {ApiClient} from '../ApiClient';
import {AbstractApiClientModule} from '../Module';
import {IEntity} from './Entity';
import {
	IAddressFieldMapping,
	IConstantFieldMapping,
	IFieldMapping, IFieldMappingTranslation,
	IImageFieldMapping,
	IMileageFieldMapping,
	ITemplateFieldMapping,
	MappingType,
} from './Feed';

type OmittedMappingKeys = 'id' | 'type' | 'targetField' | 'translations';

export interface IMappingTemplateField<T, A extends IFieldMapping> {
	type: T;

	targetField?: string;
	template?: number;
	translations?: IFieldMappingTranslation[];
	arguments?: Omit<A, OmittedMappingKeys>;
}

export interface IAddressMappingTemplateField extends IMappingTemplateField<MappingType.ADDRESS, IAddressFieldMapping> {
}

export interface IConstantMappingTemplateField
	extends IMappingTemplateField<MappingType.CONSTANT, IConstantFieldMapping> {
}

export interface IImageMappingTemplateField extends IMappingTemplateField<MappingType.IMAGE, IImageFieldMapping> {
}

export interface IMileageMappingTemplateField extends IMappingTemplateField<MappingType.MILEAGE, IMileageFieldMapping> {
}

export interface ITemplateMappingTemplateField
	extends IMappingTemplateField<MappingType.TEMPLATE, ITemplateFieldMapping> {
}

export type TemplateFieldTypes =
	IAddressMappingTemplateField
	| IConstantMappingTemplateField
	| IImageMappingTemplateField
	| IMileageMappingTemplateField
	| ITemplateMappingTemplateField;

export interface IFeedMappingTemplate extends IEntity {
	name?: string;
	fields?: TemplateFieldTypes[];
}

export class FeedMappingTemplateApiClientModule extends AbstractApiClientModule<IFeedMappingTemplate> {
	public constructor(client: ApiClient) {
		super(client, '/feed-templates');
	}
}
