import {Colors, UL} from '@blueprintjs/core';
import * as React from 'react';
import {feedFieldLabels, requiredFeedFields} from '../../../Facebook/FeedFields';
import './RequiredFieldsNotice.scss';

interface IRequiredFieldsNoticeProps {
	fields: string[];
}

export const RequiredFieldsNotice: React.FC<IRequiredFieldsNoticeProps> = props => {
	const missingRequiredFields = requiredFeedFields
		.filter(field => props.fields.indexOf(field) === -1)
		.map(field => feedFieldLabels[field]);

	return (
		<>
			{missingRequiredFields.length > 0 && (
				<div id="required-fields-notice">
					<p style={{fontWeight: 'bold', color: Colors.RED4, fontSize: 16}}>
						You are missing {missingRequiredFields.length} required
						field{missingRequiredFields.length !== 1 ? 's' : ''}.
					</p>

					<UL>
						{missingRequiredFields.map(field => <li key={field}>{field}</li>)}
					</UL>
				</div>
			)}
		</>
	);
};
