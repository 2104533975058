import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {FieldMappingTypes, IFieldMappingTranslation} from '../../../Api/Objects/Feed';
import {IThemeAware, withTheme} from '../../Contexts/ThemeContext';
import {Theme} from '../../Theme/Theme';

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

interface ITranslationDialogProps extends IThemeAware {
	mapping: FieldMappingTypes;
	onDismiss: () => void;
	onSave: (mapping: FieldMappingTypes, translations: IFieldMappingTranslation[]) => void;
}

interface ITranslationDialogState {
	translations: IFieldMappingTranslation[];
}

class TranslationDialogComponent extends React.PureComponent<ITranslationDialogProps, ITranslationDialogState> {
	public constructor(props: ITranslationDialogProps) {
		super(props);

		this.state = {
			translations: props.mapping.translations,
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={true}
				onClose={this.props.onDismiss}
				title="Manage Translations"
			>
				<div className={Classes.DIALOG_BODY}>
					<form onSubmit={this.save}>
						{this.state.translations.map((translation, index) => (
							<Row key={index}>
								<Cell size={5}>
									<FormGroup label="Source" labelFor={`translation-${index}`}>
										<InputGroup
											name={`translation-${index}`}
											value={translation.source}
											onChange={(event: ChangeEvent) => this.onTranslationSourceChange(
												event,
												index,
											)}
										/>
									</FormGroup>
								</Cell>

								<Cell size={5}>
									<FormGroup label="Value" labelFor={`translation-${index}`}>
										<InputGroup
											name={`translation-${index}`}
											value={translation.value}
											onChange={(event: ChangeEvent) => this.onTranslationValueChange(
												event,
												index,
											)}
										/>
									</FormGroup>
								</Cell>

								<Cell size={2} className="text-right">
									<FormGroup label={<span>&nbsp;</span>}>
										<Button icon="cross" onClick={() => this.onRemoveClick(index)} />
									</FormGroup>
								</Cell>
							</Row>
						))}
					</form>

					<Button icon="plus" onClick={this.onAddTranslationClick}>
						Add Translation
					</Button>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={this.props.onDismiss}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} onClick={this.save}>
							Save
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private onAddTranslationClick = () => this.setState({
		translations: [...this.state.translations, {source: '', value: ''}],
	});

	private onRemoveClick = (index: number) => {
		const translations = [...this.state.translations];
		translations.splice(index, 1);

		this.setState({
			translations,
		});
	};

	private onTranslationSourceChange = (event: ChangeEvent, index: number) => {
		this.state.translations[index].source = event.currentTarget.value;

		this.setState({
			translations: [...this.state.translations],
		});
	};

	private onTranslationValueChange = (event: ChangeEvent, index: number) => {
		this.state.translations[index].value = event.currentTarget.value;

		this.setState({
			translations: [...this.state.translations],
		});
	};

	private save = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		this.props.onSave(this.props.mapping, this.state.translations);
	};
}

export const TranslationDialog = withTheme(TranslationDialogComponent);
