import * as React from 'react';
import {Link} from 'react-router-dom';
import {IAccount} from '../../../Api/Objects/Account';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {createEntityFilter, createEntitySorter, EntityList} from '../EntityList';

type Account = Pick<IAccount, 'id' | 'createdDate' | 'name' | 'catalogs' | 'pixels'>;

const sorter = createEntitySorter<Account>('name');
const filterEntityOnName = createEntityFilter<Account>('name');

const AccountListComponent: React.FC<IApiClientAware> = props => (
	<EntityList
		basePath="/edit/accounts"
		deleteToastMessage={target => `${target.name} has been deleted successfully.`}
		projection={{
			'catalogs.id': true,
			createdDate: true,
			name: true,
		}}
		provider={props.client.accounts}
		sorter={sorter}
		tableColumns={[
			{
				dataIndex: 'name',
				onFilter: filterEntityOnName,
				title: 'Name',
			},
			{
				render: record => record.createdDate.toLocaleString(),
				title: 'Created Date',
			},
			{
				render: record => (
					<Link to={`/edit/accounts/${record.id}/catalogs`}>
						{record.catalogs.length} Catalog{record.catalogs.length !== 1 ? 's' : ''}
					</Link>
				),
				title: 'Catalogs',
			},
		]}
		tableNoDataPlaceholder={<span>No accounts found.</span>}
		title="Accounts"
	/>
);

export const AccountList = withApiClient(AccountListComponent);
