import {Role} from '../../Components/RequireRole';
import {ApiClient} from '../ApiClient';
import {AbstractApiClientModule} from '../Module';
import {IEntity} from './Entity';

export interface IUser extends IEntity {
	activationUrl?: string;
	archived?: boolean;
	createdDate?: Date;
	email?: string;
	firstName?: string;
	lastName?: string;
	roles?: Role[];
}

export class UserApiClientModule extends AbstractApiClientModule<IUser> {
	public constructor(client: ApiClient) {
		super(client, '/users');
	}

	public activate(code: string, password: string) {
		return this.client.fetch('POST', `/users/activate/${code}`, null, {
			password,
		});
	}

	public sendPasswordResetCode(email: string) {
		return this.client.fetch('POST', '/users/password-reset', null, {
			email,
			passwordResetUrl: `${window.location.protocol}//${window.location.host}/password-reset/:code`,
		});
	}

	public resetPassword(code: string, password: string) {
		return this.client.fetch('POST', `/users/password-reset/${code}`, null, {
			password,
		});
	}

	protected denormalize(entity: IUser): IUser {
		if (typeof entity.createdDate === 'string')
			entity.createdDate = new Date(entity.createdDate);

		return entity;
	}
}
