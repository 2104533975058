import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from '@blueprintjs/core';
import {Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {ITimestampFieldMapping, MappingType} from '../../../../Api/Objects/Feed';
import {IThemeAware, withTheme} from '../../../Contexts/ThemeContext';
import {Theme} from '../../../Theme/Theme';
import {FeedFieldsSelect} from '../FeedFieldsSelect';
import {IMappingDialogProps} from '../FieldMappingEditor';
import {FlexibleSourceHeaderSelect} from './FlexibleSourceHeaderSelect';

interface IFormat {
	format: string;
	label: string;
}

const formats: IFormat[] = [
	{
		format: 'Y-m-d',
		label: 'Date',
	},
	{
		format: 'Y-m-d\\TH:i:sO',
		label: 'Date and Time',
	},
];

interface IProps extends IMappingDialogProps<ITimestampFieldMapping>, IThemeAware {
}

interface IState {
	sourceField: string;
	sourceFieldFormat: string;
	targetField: string;
	targetFieldFormat: IFormat;
}

class TimestampMappingDialogComponent extends React.PureComponent<IProps, IState> {
	public state: Readonly<IState> = {
		sourceField: '',
		sourceFieldFormat: '',
		targetField: null,
		targetFieldFormat: null,
	};

	public constructor(props: IProps) {
		super(props);

		const mapping = props.mapping || {
			type: MappingType.TIMESTAMP,
		};

		let targetFieldFormat: IFormat;

		if (mapping.targetFieldFormat) {
			targetFieldFormat = formats.find(item => item.format === mapping.targetFieldFormat) || {
				format: mapping.targetFieldFormat,
				label: `${mapping.targetFieldFormat} (Custom)`,
			};
		} else
			targetFieldFormat = formats[0];

		this.state = {
			sourceField: mapping.sourceField || '',
			sourceFieldFormat: mapping.sourceFieldFormat || '',
			targetField: mapping.targetField || null,
			targetFieldFormat,
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={true}
				onClose={this.props.onDismiss}
				title={`${this.props.mapping ? 'Edit' : 'Add'} Timestamp Mapping`}
			>
				<div className={Classes.DIALOG_BODY}>
					<form onSubmit={this.save}>
						<FormGroup label="Target Field">
							<FeedFieldsSelect
								omit={this.props.omitMappingFields}
								onItemSelect={this.onTargetFieldSelect}
								selected={this.state.targetField}
							/>
						</FormGroup>

						<FormGroup
							label="Target Field Format"
							labelFor="targetFieldFormat"
						>
							<Select
								items={formats}
								itemTextRenderer={this.renderTargetFieldFormat}
								noItemSelected="Select a format..."
								onItemSelect={this.onTargetFieldFormatSelect}
								popoverProps={{
									targetClassName: 'full-width',
								}}
								selected={this.state.targetFieldFormat}
							/>
						</FormGroup>

						<FormGroup label="Source Field">
							<FlexibleSourceHeaderSelect
								headers={this.props.headers}
								onChange={this.onSourceFieldChange}
								selected={this.state.sourceField}
							/>
						</FormGroup>

						<FormGroup
							helperText="Leave blank to let Dynamic Ads infer the source format"
							label="Source Field Format"
							labelFor="sourceFieldFormat"
							labelInfo={(
								<span>
									<a href="http://php.net/manual/en/function.date.php" target="_blank">Click here</a>
									&nbsp;for format characters
								</span>
							)}
						>
							<InputGroup
								name="sourceFieldFormat"
								onChange={this.onSourceFieldFormatChange}
								value={this.state.sourceFieldFormat}
							/>
						</FormGroup>
					</form>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={this.props.onDismiss}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} onClick={this.save}>
							Save
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private renderTargetFieldFormat = (format: IFormat) => format.label;

	private onTargetFieldFormatSelect = (targetFieldFormat: IFormat) => this.setState({
		targetFieldFormat,
	});

	private onTargetFieldSelect = (targetField: string) => this.setState({
		targetField,
	});

	private onSourceFieldChange = (sourceField: string) => this.setState({
		sourceField,
	});

	private onSourceFieldFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		sourceFieldFormat: event.currentTarget.value,
	});

	private save = (event?: React.SyntheticEvent<any>) => {
		if (event)
			event.preventDefault();

		const mapping = this.props.mapping || {
			type: MappingType.TIMESTAMP,
		};

		mapping.sourceField = this.state.sourceField.trim();
		mapping.targetField = this.state.targetField;
		mapping.targetFieldFormat = this.state.targetFieldFormat.format;

		if (this.state.sourceFieldFormat !== null)
			mapping.sourceFieldFormat = this.state.sourceFieldFormat.trim();
		else
			mapping.sourceFieldFormat = null;

		this.props.mapping ? this.props.onSave(mapping) : this.props.onCreate(mapping);
	};
}

export const TimestampMappingDialog = withTheme(TimestampMappingDialogComponent);
