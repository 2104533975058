import * as React from 'react';
import {Route} from 'react-router';
import {Switch} from '../../Navigation/Switch';
import {CatalogEditor} from '../Catalogs/CatalogEditor';
import {CatalogList} from '../Catalogs/CatalogList';
import {FeedEditor} from '../Feeds/FeedEditor';
import {FeedList} from '../Feeds/FeedList';
import {AccountEditor} from './AccountEditor';
import {AccountList} from './AccountList';

export const Accounts: React.FC<{}> = () => (
	<Switch>
		<Route path="/edit/accounts" exact={true} component={AccountList} />
		<Route path="/edit/accounts/:account(\d+|new)" exact={true} component={AccountEditor} />

		<Route path="/edit/accounts/:account(\d+)/catalogs" exact={true} component={CatalogList} />
		<Route path="/edit/accounts/:account(\d+)/catalogs/:catalog(\d+|new)" exact={true} component={CatalogEditor} />

		<Route path="/edit/accounts/:account(\d+)/catalogs/:catalog(\d+)/feeds" exact={true} component={FeedList} />
		<Route
			path="/edit/accounts/:account(\d+)/catalogs/:catalog(\d+)/feeds/:feed(\d+|new)"
			exact={true}
			component={FeedEditor}
		/>
	</Switch>
);
