import {Button, FormGroup, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {Cell, Row, Select} from '@dbstudios/blueprintjs-components';
import {debounce} from 'debounce';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {isAbortError} from '../../../Api/ApiClient';
import {IConstraintViolations, isConstraintViolationError} from '../../../Api/Errors/ApiError';
import {compareFields} from '../../../Api/Objects/Entity';
import {FieldMappingTypes, IFeed, UpdateFrequency} from '../../../Api/Objects/Feed';
import {Projection} from '../../../Api/Projection';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../../Contexts/ToasterContext';
import {LinkButton} from '../../Navigation/LinkButton';
import {ValidationAwareFormGroup} from '../../ValidationAwareFormGroup';
import {FieldMappingEditor} from './FieldMappingEditor';
import {RequiredFieldsNotice} from './RequiredFieldsNotice';

const frequencyNames: { [key in UpdateFrequency]: string } = {
	[UpdateFrequency.HOURLY]: 'Hourly',
	[UpdateFrequency.DAILY]: 'Daily',
};

interface IRouteProps {
	account: string;
	catalog: string;
	feed: string;
}

interface IFeedEditorProps extends IApiClientAware, IToasterAware, RouteComponentProps<IRouteProps> {
}

interface IFeedEditorState {
	deleteMissingProducts: boolean;
	dirtyFieldMappings: boolean;
	facebookId: string;
	feedHeaders: string[];
	feedHeadersController: AbortController;
	feedHeadersLoading: boolean;
	fieldMappings: FieldMappingTypes[];
	loading: boolean;
	name: string;
	omitMappingFields: string[];
	redirect: boolean;
	saving: boolean;
	updateFrequency: UpdateFrequency;
	url: string;
	violations: IConstraintViolations;
}

class FeedEditorComponent extends React.PureComponent<IFeedEditorProps, IFeedEditorState> {
	public state: Readonly<IFeedEditorState> = {
		deleteMissingProducts: true,
		dirtyFieldMappings: false,
		facebookId: '',
		feedHeaders: [],
		feedHeadersController: null,
		feedHeadersLoading: false,
		fieldMappings: [],
		loading: true,
		name: '',
		omitMappingFields: [],
		redirect: false,
		saving: false,
		updateFrequency: UpdateFrequency.DAILY,
		url: '',
		violations: null,
	};

	private loadFeedHeaders = debounce(() => {
		if (this.state.feedHeadersController)
			this.state.feedHeadersController.abort();

		if (this.state.url.length === 0) {
			this.setState({
				feedHeaders: [],
			});

			return;
		}

		const controller = new AbortController();

		this.setState({
			feedHeadersController: controller,
			feedHeadersLoading: true,
		});

		this.props.client.feeds.headers(this.state.url, controller.signal).then(feedHeaders => this.setState({
			feedHeaders: feedHeaders.sort(),
			feedHeadersLoading: false,
		})).catch((error: Error) => {
			if (isAbortError(error))
				return;

			this.props.toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				feedHeadersController: null,
				feedHeadersLoading: false,
			});
		});
	}, 250);

	public componentDidMount(): void {
		const idParam = this.props.match.params.feed;

		if (idParam === 'new') {
			this.setState({
				loading: false,
			});

			return;
		}

		this.props.client.feeds.get(parseInt(idParam, 10)).then(feed => this.setState({
			deleteMissingProducts: feed.settings.deleteMissingProducts,
			facebookId: feed.facebookId.toString(10),
			fieldMappings: feed.fieldMappings.sort((a, b) => compareFields('targetField', a, b)),
			loading: false,
			name: feed.name,
			omitMappingFields: feed.fieldMappings.map(mapping => mapping.targetField),
			updateFrequency: feed.settings.updateFrequency,
			url: feed.url,
		}, () => this.loadFeedHeaders()));
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;

		const {account: accountId, catalog: catalogId} = this.props.match.params;

		if (this.state.redirect)
			return <Redirect to={`/edit/accounts/${accountId}/catalogs/${catalogId}/feeds`} />;

		return (
			<>
				<H2>{this.state.name.length ? this.state.name : <em>No Name</em>}</H2>

				<form>
					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Name"
								labelFor="name"
								violations={this.state.violations}
							>
								<InputGroup name="name" onChange={this.onNameChange} value={this.state.name} />
							</ValidationAwareFormGroup>
						</Cell>

						{this.props.match.params.feed !== 'new' && (
							<Cell size={6}>
								<FormGroup label="Facebook ID">
									<InputGroup
										name="facebookId"
										readOnly={true}
										style={{cursor: 'copy'}}
										value={this.state.facebookId}
									/>
								</FormGroup>
							</Cell>
						)}
					</Row>

					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Feed URL"
								labelFor="url"
								violations={this.state.violations}
							>
								<InputGroup
									name="url"
									onChange={this.onUrlChange}
									rightElement={this.state.feedHeadersLoading && (
										<div style={{marginTop: 3, marginRight: 3}}>
											<Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_SMALL} />
										</div>
									)}
									value={this.state.url}
								/>
							</ValidationAwareFormGroup>
						</Cell>

						<Cell size={3}>
							<ValidationAwareFormGroup
								label="Update Frequency"
								labelFor="updateFrequency"
								violations={this.state.violations}
							>
								<Select
									items={[UpdateFrequency.HOURLY, UpdateFrequency.DAILY]}
									itemTextRenderer={this.renderUpdateFrequencyValue}
									onItemSelect={this.onUpdateFrequencySelect}
									popoverProps={{
										targetClassName: 'full-width',
									}}
									selected={this.state.updateFrequency}
								/>
							</ValidationAwareFormGroup>
						</Cell>

						<Cell size={3}>
							<ValidationAwareFormGroup
								label="Delete Missing Products"
								labelFor="deleteMissingProducts"
								violations={this.state.violations}
							>
								<Select
									items={['Yes', 'No']}
									onItemSelect={this.onDeleteMissingProductsSelect}
									popoverProps={{
										targetClassName: 'full-width',
									}}
									selected={this.state.deleteMissingProducts ? 'Yes' : 'No'}
								/>
							</ValidationAwareFormGroup>
						</Cell>
					</Row>

					<FieldMappingEditor
						headers={this.state.feedHeaders}
						headersLoading={this.state.feedHeadersLoading}
						mappings={this.state.fieldMappings}
						omitMappingFields={this.state.omitMappingFields}
						onMappingAdd={this.onFieldMappingAdd}
						onMappingEdit={this.onFieldMappingEdit}
						onMappingRemove={this.onFieldMappingRemove}
					/>

					<RequiredFieldsNotice fields={this.state.omitMappingFields} />

					<Row align="end">
						<Cell size={1}>
							<LinkButton
								buttonProps={{
									fill: true,
									loading: this.state.saving,
								}}
								to={`/edit/accounts/${accountId}/catalogs/${catalogId}/feeds`}
							>
								Cancel
							</LinkButton>
						</Cell>

						<Cell size={1}>
							<Button
								fill={true}
								intent={Intent.PRIMARY}
								loading={this.state.saving}
								onClick={this.save}
							>
								Save
							</Button>
						</Cell>
					</Row>
				</form>
			</>
		);
	}

	private renderUpdateFrequencyValue = (frequency: UpdateFrequency) => frequencyNames[frequency];

	private onDeleteMissingProductsSelect = (value: 'Yes' | 'No') => this.setState({
		deleteMissingProducts: value === 'Yes',
	});

	private onFieldMappingAdd = (mapping: FieldMappingTypes) => this.setState({
		dirtyFieldMappings: true,
		fieldMappings: [...this.state.fieldMappings, mapping],
		omitMappingFields: [...this.state.omitMappingFields, mapping.targetField],
	});

	private onFieldMappingEdit = () => this.setState({
		dirtyFieldMappings: true,
		fieldMappings: [...this.state.fieldMappings],
		omitMappingFields: this.state.fieldMappings.map(mapping => mapping.targetField),
	});

	private onFieldMappingRemove = (target: FieldMappingTypes) => this.setState({
		dirtyFieldMappings: true,
		fieldMappings: this.state.fieldMappings.filter(mapping => mapping !== target),
		omitMappingFields: this.state.omitMappingFields.filter(field => field !== target.targetField),
	});

	private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		name: event.currentTarget.value,
	});

	private onUpdateFrequencySelect = (updateFrequency: UpdateFrequency) => this.setState({
		updateFrequency,
	});

	private onUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		url: event.currentTarget.value,
	}, () => this.loadFeedHeaders());

	private save = () => {
		if (this.state.saving)
			return;

		this.setState({
			saving: true,
		});

		const payload: IFeed = {
			catalog: parseInt(this.props.match.params.catalog, 10),
			name: this.state.name,
			settings: {
				deleteMissingProducts: this.state.deleteMissingProducts,
				updateFrequency: this.state.updateFrequency,
			},
			url: this.state.url,
		};

		if (this.state.dirtyFieldMappings)
			payload.fieldMappings = this.state.fieldMappings;

		const projection: Projection = {
			name: true,
		};

		const idParam = this.props.match.params.feed;
		let promise: Promise<IFeed>;

		if (idParam === 'new')
			promise = this.props.client.feeds.create(payload, projection);
		else
			promise = this.props.client.feeds.update(parseInt(idParam, 10), payload, projection);

		promise.then(feed => {
			this.props.toaster.show({
				intent: Intent.SUCCESS,
				message: `${feed.name} ${idParam === 'new' ? 'created' : 'saved'} successfully.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			this.props.toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				saving: false,
			});

			if (isConstraintViolationError(error)) {
				this.setState({
					violations: error.context.violations,
				});
			}
		});
	};
}

export const FeedEditor = withApiClient(withToaster(withRouter(FeedEditorComponent)));
