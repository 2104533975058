import {Button, H2, Intent} from '@blueprintjs/core';
import {Cell, IColumn, Row, Table} from '@dbstudios/blueprintjs-components';
import {debounce} from 'debounce';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {isAbortError} from '../../Api/ApiClient';
import {IApiClientModule} from '../../Api/Module';
import {compareFields, IEntity} from '../../Api/Objects/Entity';
import {Projection} from '../../Api/Projection';
import {IQueryDocument} from '../../Api/Query';
import {IToasterAware, withToaster} from '../Contexts/ToasterContext';
import {SearchInput} from '../SearchInput';
import {RefreshButton} from './RefreshButton';
import {RowControls} from './RowControls';

export const createEntityFilter = <T extends IEntity>(key: keyof T) => (record: T, search: string) => {
	const value = record[key];

	if (typeof value !== 'string')
		throw new Error('This function can only operate on string values');

	return value.toLowerCase().indexOf(search) > -1;
};

export const createEntitySorter = <T extends IEntity>(key: keyof T) => (a: T, b: T) => compareFields(key, a, b);

interface IEntityListProps<T extends IEntity> extends IToasterAware {
	/**
	 * The base path to use for control button routing.
	 */
	basePath: string;

	/**
	 * The {@see Projection} to pass to the provider's `list()` method.
	 */
	projection: Projection;

	/**
	 * The {@see IApiClientModule} to use to load the entities from the API.
	 */
	provider: IApiClientModule<T>;

	/**
	 * A sort function to use to sort the entity list returned by the API.
	 */
	sorter: (a: T, b: T) => -1 | 0 | 1;

	/**
	 * An array of columns to render. The last column will always be the controls column, and does not need to be
	 * specified.
	 */
	tableColumns: Array<IColumn<T>>;

	/**
	 * A React node to render in place of the entity list of the list has no items in it. This node is used both when
	 * the API returns no objects, as well as when there are no results for a particular search string.
	 */
	tableNoDataPlaceholder: React.ReactNode;

	/**
	 * The title to display above the component.
	 */
	title: string;

	/**
	 * If `true`, all search methods will be debounced with a delay of 200ms.
	 */
	debounce?: boolean;

	/**
	 * If provided, a callback to invoke when displaying a successful delete message to the user. The return value of
	 * the function will be used as the toaster message.
	 */
	deleteToastMessage?: (item: T) => React.ReactNode;

	/**
	 * Any additional controls to render above the table. These are rendered to the left of the "Add New" button,
	 * below the title and search bar.
	 */
	extraControls?: React.ReactNode;

	/**
	 * If specified, the loading state of the table is considered controlled. Loading of the entity list will be
	 * delayed until the loading property is `true`. This is useful if you need to pass additional arguments to the
	 * {@see query} or {@see projection} properties that aren't available until some other asynchronous action is
	 * completed.
	 */
	loading?: boolean;

	/**
	 * A callback to invoke when an entity is deleted from the list.
	 */
	onDelete?: (entity: T) => void;

	/**
	 * The {@see IQueryDocument} to pass to the provider's `list()` method.
	 */
	query?: IQueryDocument;

	/**
	 * If provided, the component returned by this callback will be rendered in addition to the normal row controls.
	 */
	rowControls?: (record: T) => React.ReactNode;

	/**
	 * Sets the width of the row controls column (default 200px).
	 */
	rowControlsWidth?: number | string;
}

interface IEntityListState<T extends IEntity> {
	columns: Array<IColumn<T>>;
	controller: AbortController;
	entities: T[];
	loading: boolean;
	search: string;
}

class EntityListComponent<T extends IEntity> extends React.PureComponent<IEntityListProps<T>, IEntityListState<T>> {
	public static defaultProps: Partial<IEntityListProps<any>> = {
		debounce: true,
		rowControlsWidth: 200,
	};

	public constructor(props: IEntityListProps<T>) {
		super(props);

		this.state = {
			columns: [
				...props.tableColumns,
				{
					align: 'right',
					render: record => (
						<>
							{this.props.rowControls && this.props.rowControls(record)}

							<RowControls
								entity={record}
								editPath={`${props.basePath}/${record.id}`}
								onDelete={this.onDeleteButtonClick}
							/>
						</>
					),
					style: {
						width: this.props.rowControlsWidth,
					},
					title: '',
				},
			],
			controller: null,
			entities: [],
			loading: true,
			search: '',
		};
	}

	public componentDidMount(): void {
		if (!this.props.loading)
			this.loadEntities();
	}

	public componentWillUnmount(): void {
		if (this.state.controller)
			this.state.controller.abort();
	}

	public render(): React.ReactNode {
		return (
			<>
				<div style={{display: 'flex'}}>
					<div style={{flex: 2}}>
						<H2>
							{this.props.title}

							<RefreshButton onRefresh={this.loadEntities} />
						</H2>
					</div>

					<div style={{flex: 1}}>
						<SearchInput
							onSearch={
								this.props.debounce ? this.onSearchInputChangeDebounced : this.onSearchInputChange
							}
						/>
					</div>
				</div>

				<Row>
					<Cell size={10}>
						{this.props.extraControls}
					</Cell>

					<Cell size={2} className="text-right">
						<Link to={`${this.props.basePath}/new`} className="plain-link">
							<Button icon="plus">
								Add New
							</Button>
						</Link>
					</Cell>
				</Row>

				<div style={{marginTop: 15}}>
					<Table
						dataSource={this.state.entities}
						columns={this.state.columns}
						fullWidth={true}
						htmlTableProps={{
							interactive: true,
							striped: true,
						}}
						loading={this.state.loading}
						noDataPlaceholder={this.props.tableNoDataPlaceholder}
						rowKey="id"
						searchText={this.state.search}
					/>
				</div>
			</>
		);
	}

	private onDeleteButtonClick = (target: T) => {
		return this.props.provider.delete(target)
			.then(() => {
				let message: React.ReactNode;

				if (this.props.deleteToastMessage)
					message = this.props.deleteToastMessage(target);
				else
					message = 'Item deleted successfully.';

				this.props.toaster.show({
					intent: Intent.SUCCESS,
					message,
				});

				this.setState({
					entities: this.state.entities.filter(entity => entity.id !== target.id),
				});

				if (this.props.onDelete)
					this.props.onDelete(target);
			})
			.catch((error: Error) => {
				this.props.toaster.show({
					intent: Intent.DANGER,
					message: error.message,
				});
			});
	};

	private onSearchInputChange = (search: string) => this.setState({
		search: search.toLowerCase(),
	});

	// tslint:disable-next-line:member-ordering
	private onSearchInputChangeDebounced = debounce(this.onSearchInputChange, 200);

	private loadEntities = () => {
		if (this.state.controller)
			this.state.controller.abort();

		const controller = new AbortController();

		this.setState({
			controller,
			loading: true,
		});

		this.props.provider.list(this.props.query, {...this.props.projection, id: true}, controller.signal)
			.then(entities => this.setState({
				controller: null,
				entities: entities.sort(this.props.sorter),
				loading: false,
			}))
			.catch((error: Error) => {
				if (isAbortError(error))
					return;

				this.props.toaster.show({
					intent: Intent.DANGER,
					message: error.message,
				});

				this.setState({
					loading: false,
				});
			});
	};
}

export const EntityList = withToaster(EntityListComponent);
