import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from '@blueprintjs/core';
import {Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {ITemplateFieldMapping, MappingType} from '../../../../Api/Objects/Feed';
import {IThemeAware, withTheme} from '../../../Contexts/ThemeContext';
import {Theme} from '../../../Theme/Theme';
import {FeedFieldsSelect} from '../FeedFieldsSelect';
import {IMappingDialogProps} from '../FieldMappingEditor';

interface ITemplateMappingDialogProps extends IMappingDialogProps<ITemplateFieldMapping>, IThemeAware {
}

interface ITemplateMappingDialogState {
	targetField: string;
	template: string;
}

class TemplateMappingDialogComponent
	extends React.PureComponent<ITemplateMappingDialogProps, ITemplateMappingDialogState> {
	public constructor(props: ITemplateMappingDialogProps) {
		super(props);

		const mapping = props.mapping || {
			type: MappingType.TEMPLATE,
		};

		this.state = {
			targetField: mapping.targetField || '',
			template: mapping.template || '',
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={true}
				onClose={this.props.onDismiss}
				title={`${this.props.mapping ? 'Edit' : 'Add'} Template Mapping`}
			>
				<div className={Classes.DIALOG_BODY}>
					<form onSubmit={this.save}>
						<FormGroup label="Target Field">
							<FeedFieldsSelect
								omit={this.props.omitMappingFields}
								onItemSelect={this.onTargetFieldSelect}
								selected={this.state.targetField}
							/>
						</FormGroup>

						<FormGroup
							label="Template"
							labelFor="template"
							helperText={(
								<span>
									Wrap column names in <code className={Classes.CODE}>
										{'{{'} Double Curly Brackets {'}}'}
									</code>
								</span>
							)}
						>
							<InputGroup
								name="template"
								onChange={this.onTemplateChange}
								rightElement={this.props.headers.length > 0 && (
									<Select
										buttonProps={{
											minimal: true,
											rightIcon: 'caret-down',
										}}
										itemListPredicate={this.onInsertListFilter}
										items={this.props.headers}
										onItemSelect={this.onInsertItemSelect}
										selected="Insert"
									/>
								)}
								value={this.state.template}
							/>
						</FormGroup>
					</form>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={this.props.onDismiss}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} onClick={this.save}>
							Save
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private onInsertItemSelect = (item: string) => this.setState({
		template: `${this.state.template}{{${item}}}`,
	});

	private onInsertListFilter = (query: string, items: string[]) => {
		query = query.toLowerCase();

		return items.filter(item => item.toLowerCase().indexOf(query) !== -1);
	};

	private onTargetFieldSelect = (targetField: string) => this.setState({
		targetField,
	});

	private onTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		template: event.currentTarget.value,
	});

	private save = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		const mapping = this.props.mapping || {
			type: MappingType.TEMPLATE,
		};

		mapping.targetField = this.state.targetField;
		mapping.template = this.state.template.trim();

		this.props.mapping ? this.props.onSave(mapping) : this.props.onCreate(mapping);
	};
}

export const TemplateMappingDialog = withTheme(TemplateMappingDialogComponent);
