import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from '@blueprintjs/core';
import * as React from 'react';
import {IConstantFieldMapping, MappingType} from '../../../../Api/Objects/Feed';
import {IThemeAware, withTheme} from '../../../Contexts/ThemeContext';
import {Theme} from '../../../Theme/Theme';
import {FeedFieldsSelect} from '../FeedFieldsSelect';
import {IMappingDialogProps} from '../FieldMappingEditor';

interface IConstantMappingDialogProps extends IMappingDialogProps<IConstantFieldMapping>, IThemeAware {
}

interface IConstantMappingDialogState {
	fieldValue: string;
	targetField: string;
}

class ConstantMappingDialogComponent
	extends React.PureComponent<IConstantMappingDialogProps, IConstantMappingDialogState> {
	public constructor(props: IConstantMappingDialogProps) {
		super(props);

		const mapping = props.mapping || {
			type: MappingType.CONSTANT,
		};

		this.state = {
			fieldValue: mapping.fieldValue || '',
			targetField: mapping.targetField || '',
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={true}
				onClose={this.props.onDismiss}
				title={`${this.props.mapping ? 'Edit' : 'Add'} Constant Mapping`}
			>
				<div className={Classes.DIALOG_BODY}>
					<form onSubmit={this.save}>
						<FormGroup label="Target Field">
							<FeedFieldsSelect
								omit={this.props.omitMappingFields}
								onItemSelect={this.onTargetFieldSelect}
								selected={this.state.targetField}
							/>
						</FormGroup>

						<FormGroup label="Field Value" labelFor="fieldValue">
							<InputGroup
								name="fieldValue"
								onChange={this.onFieldValueChange}
								value={this.state.fieldValue}
							/>
						</FormGroup>
					</form>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={this.props.onDismiss}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} onClick={this.save}>
							Save
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private onFieldValueChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		fieldValue: event.currentTarget.value,
	});

	private onTargetFieldSelect = (targetField: string) => this.setState({
		targetField,
	});

	private save = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		const mapping = this.props.mapping || {
			type: MappingType.CONSTANT,
		};

		mapping.fieldValue = this.state.fieldValue;
		mapping.targetField = this.state.targetField;

		this.props.mapping ? this.props.onSave(mapping) : this.props.onCreate(mapping);
	};
}

export const ConstantMappingDialog = withTheme(ConstantMappingDialogComponent);
