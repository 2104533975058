import {Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {filterStrings} from '../Utility/select';

// region US States Array
const states = [
	'Alabama',
	'Alaska',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'Florida',
	'Georgia',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Pennsylvania',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
];

// endregion

interface IStateListProps {
	onItemSelect: (item: string) => void;
	selected: string;
}

export const StateSelect: React.FC<IStateListProps> = props => (
	<Select
		itemListPredicate={filterStrings}
		items={states}
		noItemSelected="Select a state..."
		onItemSelect={props.onItemSelect}
		popoverProps={{
			targetClassName: 'full-width',
		}}
		selected={props.selected}
	/>
);
