import {Intent} from '@blueprintjs/core';
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {IApiClientAware, withApiClient} from '../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../Contexts/ToasterContext';

interface ILogoutHandlerProps extends RouteComponentProps<{}>, IToasterAware, IApiClientAware {
}

class LogoutHandlerComponent extends React.Component<ILogoutHandlerProps, {}> {
	public render(): JSX.Element {
		return (
			<div
				onClick={this.onClick}
			>
				{this.props.children}
			</div>
		);
	}

	private onClick = () => {
		this.props.client.logout();
		this.props.history.push('/login');

		this.props.toaster.show({
			intent: Intent.PRIMARY,
			message: 'You have been logged out.',
		});
	};
}

export const LogoutHandler = withApiClient(withToaster(withRouter(LogoutHandlerComponent)));
