import {Breadcrumb, Breadcrumbs, IBreadcrumbProps} from '@blueprintjs/core';
import * as React from 'react';
import {Link} from 'react-router-dom';

export const BreadcrumbItems = {
	accounts: () => ({
		href: '/edit/accounts',
		text: 'Accounts',
	}),
	catalogs: (account: number | string) => ({
		href: `/edit/accounts/${account}/catalogs`,
		text: 'Catalogs',
	}),
	feeds: (account: number | string, catalog: number | string) => ({
		href: `/edit/accounts/${account}/catalogs/${catalog}/feeds`,
		text: 'Feeds',
	}),
};

const renderBreadcrumbs = ({text, href, ...props}: IBreadcrumbProps) => {
	let child: React.ReactNode;

	if (href) {
		child = (
			<Link style={{color: 'inherit'}} to={href}>
				{text}
			</Link>
		);
	} else
		child = text;

	return (
		<Breadcrumb {...props}>
			{child}
		</Breadcrumb>
	);
};

const renderCurrentBreadcrumb = ({text, href, ...props}: IBreadcrumbProps) => (
	<Breadcrumb {...props}>
		<strong>
			{text}
		</strong>
	</Breadcrumb>
);

interface IEditorBreadcrumbsProps {
	items: IBreadcrumbProps[];
}

export const EditorBreadcrumbs: React.FC<IEditorBreadcrumbsProps> = props => (
	<Breadcrumbs
		currentBreadcrumbRenderer={renderCurrentBreadcrumb}
		breadcrumbRenderer={renderBreadcrumbs}
		items={props.items}
		overflowListProps={{style: {marginBottom: 10}}}
	/>
);
