import {ApiClient} from '../ApiClient';
import {AbstractApiClientModule} from '../Module';
import {IEntity} from './Entity';

export interface IPixel extends IEntity {
	account?: number;
	name?: string;
	facebookId?: number;
}

export class PixelApiClientModule extends AbstractApiClientModule<IPixel> {
	public constructor(client: ApiClient) {
		super(client, '/pixels');
	}
}
