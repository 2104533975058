import * as React from 'react';
import {Redirect, Route, RouteComponentProps, RouteProps} from 'react-router';
import {IApiClientAware, withApiClient} from '../Components/Contexts/ApiClientContext';

type RenderCallback = (props: RouteComponentProps<any>) => React.ReactNode;
type RendererProps = RouteComponentProps<any> & RouteProps;

interface IPrivateRouteComponentProps extends RouteProps, IApiClientAware {
}

const PrivateRouteComponent: React.FC<IPrivateRouteComponentProps> = ({
	component,
	render,
	client,
	// tslint:disable-next-line
	...routeProps
}) => {
	const doRender = (Component?: React.ComponentType, renderFunc?: RenderCallback) => (props: RendererProps) => {
		if (!Component && !renderFunc) {
			return null;
		}

		if (client.isAuthenticated()) {
			return Component ? <Component {...props} /> : renderFunc(props);
		}

		const to = {
			pathname: '/login',
			state: {
				from: props.location,
			},
		};

		return <Redirect to={to} />;
	};

	return <Route {...routeProps} render={doRender(component, render)} />;
};

export const PrivateRoute = withApiClient(PrivateRouteComponent);
