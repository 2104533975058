import {Button, FormGroup, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {IConstraintViolation, IConstraintViolations, isConstraintViolationError} from '../../../Api/Errors/ApiError';
import {IAccount} from '../../../Api/Objects/Account';
import {IFacebookAccount} from '../../../Api/Objects/FacebookAccount';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../../Contexts/ToasterContext';
import {LinkButton} from '../../Navigation/LinkButton';
import {EntitySelect} from '../../Select/EntitySelect';
import {ValidationAwareFormGroup} from '../../ValidationAwareFormGroup';

interface IRouteProps {
	account: string;
}

interface IAccountEditorProps extends IApiClientAware, IToasterAware, RouteComponentProps<IRouteProps> {
}

interface IAccountEditorState {
	facebookAccounts: IFacebookAccount[];
	loading: boolean;
	name: string;
	redirect: boolean;
	saving: boolean;
	selectedFacebookAccount: IFacebookAccount;
	violations: IConstraintViolations;
}

class AccountEditorComponent extends React.PureComponent<IAccountEditorProps, IAccountEditorState> {
	public state: Readonly<IAccountEditorState> = {
		facebookAccounts: [],
		loading: true,
		name: '',
		redirect: false,
		saving: false,
		selectedFacebookAccount: null,
		violations: {},
	};

	public componentDidMount(): void {
		this.props.client.facebookAccounts.list().then(facebookAccounts => this.setState({
			facebookAccounts,
		}));

		const idParam = this.props.match.params.account;

		if (idParam === 'new') {
			this.setState({
				loading: false,
			});

			return;
		}

		this.props.client.accounts.get(parseInt(idParam, 10)).then(account => this.setState({
			loading: false,
			name: account.name,
			selectedFacebookAccount: account.facebookId ? {id: account.facebookId, name: null} : null,
		}));
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;
		else if (this.state.redirect)
			return <Redirect to="/edit/accounts" />;

		const errors = {
			name: this.getValidationError('name'),
		};

		return (
			<>
				<H2>{this.state.name.length ? this.state.name : <em>No Name</em>}</H2>

				<form>
					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Name"
								labelFor="name"
								violations={this.state.violations}
							>
								<InputGroup name="name" onChange={this.onNameChange} value={this.state.name} />
							</ValidationAwareFormGroup>
						</Cell>

						<Cell size={6}>
							<FormGroup
								label="Facebook Account"
								helperText="Once assigned, you cannot change which Facebook account an account is linked to."
							>
								{this.props.match.params.account === 'new' ? (
									<EntitySelect
										labelField="name"
										onSelectionLoad={this.onFacebookAccountsLoad}
										onItemSelect={this.onFacebookAccountSelect}
										provider={this.props.client.facebookAccounts}
										selected={this.state.selectedFacebookAccount}
									/>
								) : (
									<InputGroup
										name="facebookId"
										readOnly={true}
										value={this.state.selectedFacebookAccount.id}
										style={{cursor: 'copy'}}
									/>
								)}
							</FormGroup>
						</Cell>
					</Row>

					<Row>
						<Cell size={10}>
							{this.props.match.params.account !== 'new' && (
								<LinkButton to={`/edit/accounts/${this.props.match.params.account}/catalogs`}>
									View Catalogs
								</LinkButton>
							)}
						</Cell>

						<Cell size={1}>
							<LinkButton to="/edit/accounts" buttonProps={{fill: true, loading: this.state.saving}}>
								Cancel
							</LinkButton>
						</Cell>

						<Cell size={1}>
							<Button intent={Intent.PRIMARY} fill={true} onClick={this.save} loading={this.state.saving}>
								Save
							</Button>
						</Cell>
					</Row>
				</form>
			</>
		);
	}

	private onFacebookAccountSelect = (item: IFacebookAccount) => this.setState({
		selectedFacebookAccount: item,
	});

	private onFacebookAccountsLoad = (selected: IFacebookAccount) => this.setState({
		selectedFacebookAccount: selected,
	});

	private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		name: event.currentTarget.value,
	});

	private save = (event?: React.SyntheticEvent<any>) => {
		if (event)
			event.preventDefault();

		if (this.state.saving)
			return;

		this.setState({
			saving: true,
			violations: {},
		});

		const payload: IAccount = {
			name: this.state.name,
		};

		if (this.state.selectedFacebookAccount)
			payload.facebookId = this.state.selectedFacebookAccount.id;

		const id = this.props.match.params.account;
		let promise: Promise<IAccount>;

		if (id === 'new')
			promise = this.props.client.accounts.create(payload, {name: true});
		else
			promise = this.props.client.accounts.update(parseInt(id, 10), payload, {name: true});

		promise.then(account => {
			this.props.toaster.show({
				intent: Intent.SUCCESS,
				message: `${account.name} ${id === 'new' ? 'created' : 'saved'} successfully.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			this.props.toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				saving: false,
			});

			if (isConstraintViolationError(error)) {
				this.setState({
					violations: error.context.violations,
				});
			}
		});
	};

	private getValidationError = (key: string): IConstraintViolation => this.state.violations[key];
}

export const AccountEditor = withApiClient(withToaster(withRouter(AccountEditorComponent)));
