import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {IImageFieldMapping, MappingType} from '../../../../Api/Objects/Feed';
import {cleanIntegerString} from '../../../../Utility/number';
import {IThemeAware, withTheme} from '../../../Contexts/ThemeContext';
import {Theme} from '../../../Theme/Theme';
import {FeedFieldsSelect} from '../FeedFieldsSelect';
import {IMappingDialogProps} from '../FieldMappingEditor';
import {FlexibleSourceHeaderSelect} from './FlexibleSourceHeaderSelect';

interface IImageMappingDialogProps extends IMappingDialogProps<IImageFieldMapping>, IThemeAware {
}

interface IImageMappingDialogState {
	maxItems: string;
	sourceDelimiter: string;
	sourceField: string;
	targetField: string;
}

class ImageMappingDialogComponent extends React.PureComponent<IImageMappingDialogProps, IImageMappingDialogState> {
	public constructor(props: IImageMappingDialogProps) {
		super(props);

		const mapping = props.mapping || {
			type: MappingType.IMAGE,
		};

		this.state = {
			maxItems: mapping.maxItems ? mapping.maxItems.toString(10) : '',
			sourceDelimiter: mapping.sourceDelimiter || ',',
			sourceField: mapping.sourceField || '',
			targetField: mapping.targetField || '',
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={true}
				onClose={this.props.onDismiss}
				title={`${this.props.mapping ? 'Edit' : 'Add'} Image Mapping`}
			>
				<div className={Classes.DIALOG_BODY}>
					<form onSubmit={this.save}>
						<FormGroup label="Target Field">
							<FeedFieldsSelect
								omit={this.props.omitMappingFields}
								onItemSelect={this.onTargetFieldSelect}
								selected={this.state.targetField}
							/>
						</FormGroup>

						<Row>
							<Cell size={10}>
								<FormGroup label="Source Field" labelFor="sourceField">
									<FlexibleSourceHeaderSelect
										headers={this.props.headers}
										onChange={this.onSourceFieldChange}
										selected={this.state.sourceField}
									/>
								</FormGroup>
							</Cell>

							<Cell size={2}>
								<FormGroup label="Delimiter" labelFor="sourceDelimiter">
									<InputGroup
										name="sourceDelimiter"
										onChange={this.onSourceDelimiterChange}
										value={this.state.sourceDelimiter}
									/>
								</FormGroup>
							</Cell>
						</Row>

						<FormGroup label="Max Items" labelFor="maxItems">
							<InputGroup name="maxItems" onChange={this.onMaxItemsChange} value={this.state.maxItems} />
						</FormGroup>
					</form>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={this.props.onDismiss}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} onClick={this.save}>
							Save
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private onMaxItemsChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		maxItems: cleanIntegerString(event.currentTarget.value),
	});

	private onSourceDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		sourceDelimiter: event.currentTarget.value,
	});

	private onSourceFieldChange = (sourceField: string) => this.setState({
		sourceField,
	});

	private onTargetFieldSelect = (targetField: string) => this.setState({
		targetField,
	});

	private save = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		const mapping = this.props.mapping || {
			type: MappingType.IMAGE,
		};

		mapping.maxItems = parseInt(this.state.maxItems, 10);
		mapping.sourceDelimiter = this.state.sourceDelimiter;
		mapping.sourceField = this.state.sourceField;
		mapping.targetField = this.state.targetField;

		this.props.mapping ? this.props.onSave(mapping) : this.props.onCreate(mapping);
	};
}

export const ImageMappingDialog = withTheme(ImageMappingDialogComponent);
