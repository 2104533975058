import {Button, H1, Intent} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {IApiClientAware, withApiClient} from '../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../Contexts/ToasterContext';
import {PasswordChangeForm} from './PasswordChangeForm';

interface IRouteProps {
	code: string;
}

interface IUserActivationProps extends RouteComponentProps<IRouteProps>, IApiClientAware, IToasterAware {
}

interface IUserActivationState {
	isPasswordPwned: boolean;
	password: string;
	passwordMatches: boolean;
	processing: boolean;
	redirect: boolean;
}

class UserActivationComponent extends React.PureComponent<IUserActivationProps, IUserActivationState> {
	public state: Readonly<IUserActivationState> = {
		isPasswordPwned: false,
		password: '',
		passwordMatches: true,
		processing: false,
		redirect: false,
	};

	public render(): React.ReactNode {
		if (this.state.redirect)
			return <Redirect to="/" />;

		return (
			<div className="no-navbar content-container">
				<H1>Welcome!</H1>

				<p>
					Please fill out the form below to complete your registration.
				</p>

				<form onSubmit={this.onSubmit} style={{marginTop: 10}}>
					<PasswordChangeForm onPasswordChange={this.onPasswordChange} />

					<Row align="end">
						<Cell size={1}>
							<Button
								disabled={!this.state.password || !this.state.passwordMatches || this.state.isPasswordPwned}
								intent={Intent.PRIMARY}
								loading={this.state.processing}
								onClick={this.onSubmit}
							>
								Submit
							</Button>
						</Cell>
					</Row>
				</form>
			</div>
		);
	}

	private onPasswordChange = (password: string, passwordMatches: boolean) => this.setState({
		password,
		passwordMatches,
	});

	private onSubmit = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		if (this.state.processing)
			return;

		this.setState({
			processing: true,
		});

		this.props.client.users.activate(this.props.match.params.code, this.state.password)
			.then(() => {
				this.props.toaster.show({
					intent: Intent.SUCCESS,
					message: 'Your account has been activated! Please log in.',
				});

				this.props.client.logout();

				this.setState({
					redirect: true,
				});
			})
			.catch((error: Error) => {
				this.props.toaster.show({
					intent: Intent.DANGER,
					message: error.message,
				});

				this.setState({
					processing: false,
				});
			});
	};
}

export const UserActivation = withRouter(withToaster(withApiClient(UserActivationComponent)));
