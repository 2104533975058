import {Classes} from '@blueprintjs/core';
import * as React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {App} from './Components/App';
import {Login} from './Components/Auth/Login';
import {PasswordReset} from './Components/Auth/PasswordReset';
import {UserActivation} from './Components/Auth/UserActivation';
import {ApiClientContext, client} from './Components/Contexts/ApiClientContext';
import {ThemeChangerContext, ThemeContext} from './Components/Contexts/ThemeContext';
import {toaster, ToasterContext} from './Components/Contexts/ToasterContext';
import {isThemeName, Theme} from './Components/Theme/Theme';
import './Layout.scss';
import {PrivateRoute} from './Security/PrivateRoute';

const UI_THEME_STORAGE_KEY = 'ui.theme_name';

interface ILayoutState {
	theme: Theme;
}

export class Layout extends React.Component<{}, ILayoutState> {
	public constructor(props: {}) {
		super(props);

		const themeName = window.localStorage.getItem(UI_THEME_STORAGE_KEY) || Theme.DARK;
		let theme: Theme;

		if (!isThemeName(themeName)) {
			window.localStorage.setItem(UI_THEME_STORAGE_KEY, Theme.DARK);

			theme = Theme.DARK;
		} else {
			theme = themeName;
		}

		this.state = {
			theme,
		};
	}

	public render(): JSX.Element {
		const rootClasses = [];

		if (this.state.theme === Theme.DARK) {
			rootClasses.push(Classes.DARK);
		}

		return (
			<div id="app-root" className={rootClasses.join(' ')}>
				<ApiClientContext.Provider value={client}>
					<ThemeContext.Provider value={this.state.theme}>
						<ThemeChangerContext.Provider value={this.onThemeChange}>
							<ToasterContext.Provider value={toaster}>
								<BrowserRouter>
									<Switch>
										<Route path="/activate/:code([\d\w]+)" component={UserActivation} />
										<Route path="/login" component={Login} />
										<Route path="/password-reset/:code([\w\d]+)" component={PasswordReset} />

										<PrivateRoute path="/" component={App} />
									</Switch>
								</BrowserRouter>
							</ToasterContext.Provider>
						</ThemeChangerContext.Provider>
					</ThemeContext.Provider>
				</ApiClientContext.Provider>
			</div>
		);
	}

	private onThemeChange = (theme: Theme) => {
		this.setState({
			theme,
		});

		window.localStorage.setItem(UI_THEME_STORAGE_KEY, theme);
	};
}
