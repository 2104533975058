import * as React from 'react';
import {Route} from 'react-router';
import {Switch} from '../../Navigation/Switch';
import {TemplateEditor} from './TemplateEditor';
import {TemplateList} from './TemplateList';

export const Templates: React.FC<{}> = () => (
	<Switch>
		<Route path="/edit/templates" exact={true} component={TemplateList} />
		<Route path="/edit/templates/:template(\d+|new)" exact={true} component={TemplateEditor} />
	</Switch>
);
