import * as React from 'react';
import {IFeedMappingTemplate} from '../../../Api/Objects/FeedMappingTemplate';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {createEntityFilter, createEntitySorter, EntityList} from '../EntityList';

type Template = Pick<IFeedMappingTemplate, 'id' | 'name'>;

const sorter = createEntitySorter<Template>('name');
const filterEntityOnName = createEntityFilter<Template>('name');

const TemplateListComponent: React.FC<IApiClientAware> = props => (
	<EntityList
		basePath="/edit/templates"
		deleteToastMessage={target => `${target.name} has been deleted successfully.`}
		projection={{
			'fields.id': true,
			name: true,
		}}
		provider={props.client.templates}
		sorter={sorter}
		tableColumns={[
			{
				dataIndex: 'name',
				onFilter: filterEntityOnName,
				title: 'Name',
			},
			{
				render: record => `${record.fields.length} Field${record.fields.length !== 1 ? 's' : ''}`,
				title: 'Fields',
			},
		]}
		tableNoDataPlaceholder={<span>No templates found.</span>}
		title="Templates"
	/>
);

export const TemplateList = withApiClient(TemplateListComponent);
