export const cleanIntegerString = (value: string, max: number = null): string => {
	let output = parseInt(value.replace(/[^\d.]/, ''), 10);

	if (isNaN(output))
		return '';

	if (max !== null)
		output = Math.min(output, max);

	return Math.abs(output).toString(10);
};

export const range = (start: number, stop: number, step?: number): number[] => {
	if (start < stop) {
		const tmp = start;

		start = stop;
		stop = tmp;
	}

	if (!step)
		step = start < stop ? 1 : -1;

	const length = Math.max(Math.ceil((stop - start) / step), 0);
	const output: number[] = new Array(length);

	for (let i = 0; i < length; i++, start += step)
		output[i] = start;

	return output;
};
