import {ApiClient} from '../ApiClient';
import {AbstractApiClientModule} from '../Module';
import {IEntity} from './Entity';

export enum UpdateFrequency {
	HOURLY = 'hourly',
	DAILY = 'daily',
}

export interface IFeedSettings {
	updateFrequency?: UpdateFrequency;
	deleteMissingProducts?: boolean;
}

export interface IFieldMappingTranslation {
	source: string;
	value: string;
}

export enum MappingType {
	ADDRESS = 'address',
	CONSTANT = 'constant',
	IMAGE = 'image',
	MILEAGE = 'mileage',
	TEMPLATE = 'template',
	TIMESTAMP = 'timestamp',
}

export interface IFieldMapping {
	type: MappingType;

	id?: number;
	targetField?: string;
	translations?: IFieldMappingTranslation[];
}

export interface IAddressFieldMapping extends IFieldMapping {
	type: MappingType.ADDRESS;

	city?: string;
	country?: string;
	postalCode?: string;
	region?: string;
	street?: string;
}

export interface IConstantFieldMapping extends IFieldMapping {
	type: MappingType.CONSTANT;

	fieldValue?: string;
}

export interface IImageFieldMapping extends IFieldMapping {
	type: MappingType.IMAGE;

	maxItems?: number;
	sourceField?: string;
	sourceDelimiter?: string;
}

export interface IMileageFieldMapping extends IFieldMapping {
	type: MappingType.MILEAGE;

	sourceField?: string;
	unit?: string;
}

export interface ITemplateFieldMapping extends IFieldMapping {
	type: MappingType.TEMPLATE;

	template?: string;
}

export interface ITimestampFieldMapping extends IFieldMapping {
	type: MappingType.TIMESTAMP;

	sourceField?: string;
	sourceFieldFormat?: string;
	targetFieldFormat?: string;
}

export type FieldMappingTypes =
	IAddressFieldMapping
	| IConstantFieldMapping
	| IImageFieldMapping
	| IMileageFieldMapping
	| ITemplateFieldMapping
	| ITimestampFieldMapping;

export interface IFeed extends IEntity {
	catalog?: number;
	createdDate?: Date;
	downloadKey?: string;
	facebookId?: number;
	fieldMappings?: FieldMappingTypes[];
	name?: string;
	settings?: IFeedSettings;
	url?: string;
}

export class FeedApiClientModule extends AbstractApiClientModule<IFeed> {
	public constructor(client: ApiClient) {
		super(client, '/feeds');
	}

	public headers(feedUrl: string, signal?: AbortSignal): Promise<string[]> {
		return this.client.fetch('GET', '/feed-headers', {
			feedUrl,
		}, null, signal) as Promise<string[]>;
	}

	protected denormalize(entity: IFeed): IFeed {
		if (typeof entity.createdDate === 'string')
			entity.createdDate = new Date(entity.createdDate);

		return entity;
	}
}

export const unitToName = (unit: string) => {
	if (unit === 'MI')
		return 'miles';
	else if (unit === 'KM')
		return 'kilometers';

	return unit;
};
