import {Colors, Intent, ProgressBar} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import {debounce} from 'debounce';
import * as React from 'react';
import {toHex} from '../../Utility/buffer';

const strengthTests = [
	/[A-Z]/,
	/[a-z]/,
	/\d/,
	/[!@#$%^&*(),.?"':;\[\]{}|<>\-_+\/\\]/,
];

const strengthNames = [
	'N/A',
	'Very Poor',
	'Poor',
	'Ok',
	'Good',
	'Excellent',
];

const annoyingHashes = [
	'7068DA33A4C6BA17C63A005B4370E9C4426CE09D',
	'D853C4894513E6AFCF88A907525C766ADB46C209',
	'7293140768F91D7FDB296AF68A40CBB71E94F3AE',
	'8EA7F8ACC1AEA8A91FB65AE77938D922BCE82680',
];

export type PasswordStrengthChangeCallback = (strength: number, pwned: boolean, annoying: boolean) => void;

interface IProps {
	password: string;

	onChange?: PasswordStrengthChangeCallback;
}

interface IState {
	annoying: boolean;
	hash: string;
	processing: boolean;
	pwned: boolean;
	strength: number;
}

export class PasswordStrengthMeter extends React.PureComponent<IProps, IState> {
	public state: Readonly<IState> = {
		annoying: false,
		hash: null,
		processing: false,
		pwned: false,
		strength: 0,
	};

	public componentDidMount(): void {
		this.updateHashStats();
	}

	public componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps.password === this.props.password)
			return;

		this.updateHashStats();
	}

	public render(): React.ReactNode {
		const strength = this.state.annoying ? 1 : this.state.strength;
		let intent: Intent = Intent.DANGER;

		if (this.state.processing)
			intent = Intent.PRIMARY;
		else if (strength === 5)
			intent = Intent.SUCCESS;
		else if (strength >= 3)
			intent = Intent.WARNING;

		let strengthTitle: string;

		if (this.state.processing)
			strengthTitle = 'Processing...';
		else if (this.state.pwned)
			strengthTitle = 'Compromised';
		else
			strengthTitle = strengthNames[strength];

		return (
			<div style={{marginTop: 5}}>
				<Row style={{marginBottom: 5}}>
					<Cell size={7}>
						<small>Strength: {strengthTitle}</small>
					</Cell>

					<Cell className="text-right" size={5}>
						<small>
							<a href="https://xkcd.com/936/" target="_blank" tabIndex={-1}>
								Need help picking a password?
							</a>
						</small>
					</Cell>
				</Row>

				<ProgressBar
					intent={intent}
					stripes={this.state.processing}
					value={this.state.processing ? undefined : strength / 5}
				/>

				{this.state.pwned && (
					<p style={{color: Colors.RED4, marginTop: 5}}>
						The password you entered is in a database of compromised passwords. Please choose another.
					</p>
				)}
			</div>
		);
	}

	private updateHashStats = () => {
		if (!this.props.password.length) {
			this.setState({
				annoying: false,
				hash: null,
				pwned: false,
				strength: 0,
			});

			return;
		}

		this.setState({
			processing: true,
		});

		this.doUpdateHashStats();
	};

	// tslint:disable-next-line:member-ordering
	private doUpdateHashStats = debounce(() => {
		const encoder = new TextEncoder();

		window.crypto.subtle.digest('sha-1', encoder.encode(this.props.password)).then(digest => {
			const hash = toHex(digest).join('').toUpperCase();
			const prefix = hash.substr(0, 5);

			fetch(`https://api.pwnedpasswords.com/range/${prefix}`)
				.then(response => response.text())
				.then(pwnedList => {
					let pwned = false;

					for (const item of pwnedList.split('\n')) {
						const suffix = item.substr(0, item.indexOf(':'));

						if (hash === prefix + suffix) {
							pwned = true;

							break;
						}
					}

					// Strength ranges from 1 to 5. Strength is incremented by 1 for every regex that passes, and by 1
					// again if the password is at least 8 characters.
					//
					// Password strength is automatically one if the password is in the HIBP database.
					let strength = 0;

					if (!pwned) {
						if (this.props.password.length >= 8)
							++strength;

						for (const regex of strengthTests) {
							if (regex.test(this.props.password))
								++strength;
						}
					} else
						strength = 1;

					this.setState({
						annoying: annoyingHashes.indexOf(hash) !== -1,
						hash,
						processing: false,
						pwned,
						strength,
					}, () => {
						if (this.props.onChange)
							this.props.onChange(this.state.strength, this.state.pwned, this.state.annoying);
					});
				});
		});
	}, 350);
}
