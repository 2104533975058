export enum Theme {
	DARK = 'DARK',
	LIGHT = 'LIGHT',
}

/**
 * Contains the sequence that theme changing should follow. Each pair is a mapping of what theme should follow the theme
 * with the given key. It should be cyclical so that when the final mapping is reached, it maps back to the start of the
 * mapped sequence.
 *
 * To begin with, this is a simple sequence: DARK > LIGHT > DARK, and so on. By using this pattern now, we should be
 * able to cleanly add additional themes to the rotation in the future.
 */
export const ThemeSequence: {[key in Theme]: Theme} = {
	[Theme.DARK]: Theme.LIGHT,
	[Theme.LIGHT]: Theme.DARK,
};

export const isThemeName = (value: any): value is Theme => {
	return typeof value === 'string' && value in Theme;
};
