import {Button, FormGroup, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {IConstraintViolations, isConstraintViolationError} from '../../../Api/Errors/ApiError';
import {ICatalog} from '../../../Api/Objects/Catalog';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../../Contexts/ToasterContext';
import {LinkButton} from '../../Navigation/LinkButton';
import {ValidationAwareFormGroup} from '../../ValidationAwareFormGroup';

interface IRouteProps {
	account: string;
	catalog: string;
}

interface ICatalogEditorProps extends IApiClientAware, IToasterAware, RouteComponentProps<IRouteProps> {
}

interface ICatalogEditorState {
	defaultImageUrl: string;
	facebookId: string;
	loading: boolean;
	name: string;
	redirect: boolean;
	saving: boolean;
	violations: IConstraintViolations;
}

class CatalogEditorComponent extends React.PureComponent<ICatalogEditorProps, ICatalogEditorState> {
	public state: Readonly<ICatalogEditorState> = {
		defaultImageUrl: '',
		facebookId: '',
		loading: true,
		name: '',
		redirect: false,
		saving: false,
		violations: null,
	};

	public componentDidMount(): void {
		const idParam = this.props.match.params.catalog;

		if (idParam === 'new') {
			this.setState({
				loading: false,
			});

			return;
		}

		this.props.client.catalogs.get(parseInt(idParam, 10), {
			defaultImageUrl: true,
			facebookId: true,
			name: true,
		}).then(catalog => this.setState({
			defaultImageUrl: catalog.defaultImageUrl || '',
			facebookId: catalog.facebookId ? catalog.facebookId.toString(10) : '',
			loading: false,
			name: catalog.name,
		}));
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;
		else if (this.state.redirect)
			return <Redirect to={`/edit/accounts/${this.props.match.params.account}/catalogs`} />;

		const {account: accountId, catalog: catalogId} = this.props.match.params;

		return (
			<>
				<H2>{this.state.name.length ? this.state.name : <em>No Name</em>}</H2>

				<form>
					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup label="Name" labelFor="name" violations={this.state.violations}>
								<InputGroup name="name" onChange={this.onNameChange} value={this.state.name} />
							</ValidationAwareFormGroup>
						</Cell>

						{catalogId !== 'new' && (
							<Cell size={6}>
								<FormGroup label="Facebook ID" labelFor="facebookId">
									<InputGroup
										style={{cursor: 'copy'}}
										name="facebookId"
										readOnly={true}
										value={this.state.facebookId}
									/>
								</FormGroup>
							</Cell>
						)}
					</Row>

					<ValidationAwareFormGroup
						label="Default Image URL"
						labelFor="defaultImageUrl"
						violations={this.state.violations}
					>
						<InputGroup
							name="defaultImageUrl"
							onChange={this.onDefaultImageUrlChange}
							value={this.state.defaultImageUrl}
						/>
					</ValidationAwareFormGroup>
				</form>

				<Row align="end">
					<Cell size={10} className="text-left">
						{catalogId !== 'new' && (
							<LinkButton to={`/edit/accounts/${accountId}/catalogs/${catalogId}/feeds`}>
								View Feeds
							</LinkButton>
						)}
					</Cell>

					<Cell size={1}>
						<Button fill={true} loading={this.state.saving} onClick={this.onCancelButtonClick}>
							Cancel
						</Button>
					</Cell>

					<Cell size={1}>
						<Button fill={true} intent={Intent.PRIMARY} loading={this.state.saving} onClick={this.save}>
							Save
						</Button>
					</Cell>
				</Row>
			</>
		);
	}

	private onCancelButtonClick = () => this.setState({
		redirect: true,
	});

	private onDefaultImageUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		defaultImageUrl: event.currentTarget.value,
	});

	private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		name: event.currentTarget.value,
	});

	private save = () => {
		if (this.state.saving)
			return;

		this.setState({
			saving: true,
			violations: null,
		});

		const payload: ICatalog = {
			account: parseInt(this.props.match.params.account, 10),
			defaultImageUrl: this.state.defaultImageUrl.length > 0 ? this.state.defaultImageUrl : null,
			name: this.state.name,
		};

		const idParam = this.props.match.params.catalog;
		let promise: Promise<ICatalog>;

		if (idParam === 'new')
			promise = this.props.client.catalogs.create(payload);
		else
			promise = this.props.client.catalogs.update(parseInt(idParam, 10), payload);

		promise.then(catalog => {
			this.props.toaster.show({
				intent: Intent.SUCCESS,
				message: `${catalog.name} ${idParam === 'new' ? 'created' : 'saved'} successfully.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			this.props.toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				saving: false,
			});

			if (isConstraintViolationError(error)) {
				this.setState({
					violations: error.context.violations,
				});
			}
		});
	};
}

export const CatalogEditor = withApiClient(withToaster(withRouter(CatalogEditorComponent)));
