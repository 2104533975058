import {Button, Classes, Icon, IconName, Menu, MenuItem, Popover, Position, Tooltip} from '@blueprintjs/core';
import {Table} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {
	FieldMappingTypes,
	IFieldMapping,
	IFieldMappingTranslation,
	MappingType,
	unitToName,
} from '../../../Api/Objects/Feed';
import {IFeedMappingTemplate} from '../../../Api/Objects/FeedMappingTemplate';
import {feedFieldLabels} from '../../../Facebook/FeedFields';
import {ucfirst} from '../../../Utility/string';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {AddressMappingDialog} from './Dialogs/AddressMappingDialog';
import {ConstantMappingDialog} from './Dialogs/ConstantMappingDialog';
import {ImageMappingDialog} from './Dialogs/ImageMappingDialog';
import {MileageMappingDialog} from './Dialogs/MileageMappingDialog';
import {TemplateMappingDialog} from './Dialogs/TemplateMappingDialog';
import {TimestampMappingDialog} from './Dialogs/TimestampMappingDialog';
import {SelectTemplateDialog} from './SelectTemplateDialog';
import {TranslationDialog} from './TranslationDialog';

export interface IMappingDialogProps<T extends FieldMappingTypes> {
	headers: string[];
	omitMappingFields: string[];
	onCreate: (mapping: T) => void;
	onDismiss: () => void;
	onSave: (mapping: T) => void;

	mapping?: T;
}

const mappingTypeIcons: { [key in MappingType]: IconName } = {
	[MappingType.ADDRESS]: 'home',
	[MappingType.CONSTANT]: 'selection',
	[MappingType.IMAGE]: 'media',
	[MappingType.MILEAGE]: 'dashboard',
	[MappingType.TEMPLATE]: 'italic',
	[MappingType.TIMESTAMP]: 'time',
};

interface IFieldMappingEditorProps extends IApiClientAware {
	headers: string[];
	headersLoading: boolean;
	mappings: FieldMappingTypes[];
	omitMappingFields: string[];
	onMappingAdd: (mapping: FieldMappingTypes) => void;
	onMappingEdit: (mapping: FieldMappingTypes) => void;
	onMappingRemove: (mapping: FieldMappingTypes) => void;

	excludeTemplates?: string[];
}

interface IFieldMappingEditorState {
	activeMapping: FieldMappingTypes;
	activeTranslationMapping: FieldMappingTypes;
	activeType: MappingType;
	showTemplateDialog: boolean;
	templates: IFeedMappingTemplate[];
	templatesLoading: boolean;
}

class FieldMappingEditorComponent extends React.PureComponent<IFieldMappingEditorProps, IFieldMappingEditorState> {
	public static defaultProps: Partial<IFieldMappingEditorProps> = {
		excludeTemplates: [],
	};

	public state: Readonly<IFieldMappingEditorState> = {
		activeMapping: null,
		activeTranslationMapping: null,
		activeType: null,
		showTemplateDialog: false,
		templates: [],
		templatesLoading: true,
	};

	public componentDidMount(): void {
		this.props.client.templates.list(null, {
			id: true,
			name: true,
		}).then(templates => this.setState({
			templates: templates.filter(template => this.props.excludeTemplates.indexOf(template.id.toString()) < 0),
			templatesLoading: false,
		}));
	}

	public render(): React.ReactNode {
		let dialog: React.ReactNode = null;

		if (this.state.activeMapping || this.state.activeType) {
			const type = this.state.activeMapping ? this.state.activeMapping.type : this.state.activeType;
			let DialogClass: React.ComponentType<IMappingDialogProps<any>> = null;

			if (type === MappingType.ADDRESS)
				DialogClass = AddressMappingDialog;
			else if (type === MappingType.CONSTANT)
				DialogClass = ConstantMappingDialog;
			else if (type === MappingType.IMAGE)
				DialogClass = ImageMappingDialog;
			else if (type === MappingType.MILEAGE)
				DialogClass = MileageMappingDialog;
			else if (type === MappingType.TEMPLATE)
				DialogClass = TemplateMappingDialog;
			else if (type === MappingType.TIMESTAMP)
				DialogClass = TimestampMappingDialog;

			if (DialogClass) {
				dialog = (
					<DialogClass
						headers={this.props.headers}
						mapping={this.state.activeMapping}
						omitMappingFields={this.props.omitMappingFields}
						onCreate={this.onMappingDialogCreateMapping}
						onDismiss={this.onMappingDialogDismiss}
						onSave={this.onMappingDialogSaveMapping}
					/>
				);
			}
		}

		return (
			<>
				<Table
					dataSource={this.props.mappings}
					columns={[
						{
							render: record => (
								<span>
									<Icon icon={mappingTypeIcons[record.type]} style={{marginRight: 5}} />

									{ucfirst(record.type)}
								</span>
							),
							title: <span><Icon icon="blank" /> Type</span>,
						},
						{
							render: record => feedFieldLabels[record.targetField] || record.targetField,
							title: 'Target Field',
						},
						{
							render: record => {
								if (record.type === MappingType.ADDRESS)
									return `${record.street}, ${record.city}, ${record.region} ${record.postalCode}, ${record.country}`;
								else if (record.type === MappingType.CONSTANT)
									return record.fieldValue;
								else if (record.type === MappingType.IMAGE) {
									return (
										<span>
											<code className={Classes.CODE}>
												{record.sourceField}
											</code>, delimited by <code className={Classes.CODE}>
												{record.sourceDelimiter}
											</code> {record.maxItems > 0 && `(max ${record.maxItems} item${record.maxItems !== 1 ? 's' : ''})`}
										</span>
									);
								} else if (record.type === MappingType.MILEAGE) {
									return (
										<span>
											<code className={Classes.CODE}>
												{record.sourceField}
											</code> (in {unitToName(record.unit)})
										</span>
									);
								} else if (record.type === MappingType.TEMPLATE)
									return <code className={Classes.CODE}>{record.template}</code>;
								else if (record.type === MappingType.TIMESTAMP) {
									return (
										<span>
											<code className={Classes.CODE}>
												{record.sourceField}
											</code>

											{!!record.sourceFieldFormat && (
												<span>
													&nbsp;(using format <code className={Classes.CODE}>
														{record.sourceFieldFormat}
													</code>)
												</span>
											)}
										</span>
									);
								}

								return <span className={Classes.TEXT_MUTED}>--</span>;
							},
							title: 'Value',
						},
						{
							align: 'right',
							render: record => {
								if (record.translations.length > 0)
									return <span>{record.translations.length}</span>;

								return <span className={Classes.TEXT_MUTED}>--</span>;
							},
							title: 'Translations',
						},
						{
							align: 'right',
							render: record => (
								<>
									<Tooltip content="Edit mapping">
										<Button
											icon="edit"
											minimal={true}
											onClick={() => this.onEditClick(record)}
										/>
									</Tooltip>

									<Tooltip content="Configuration translations">
										<Button
											icon="exchange"
											minimal={true}
											onClick={() => this.onConfigureTranslationsButtonClick(record)}
										/>
									</Tooltip>

									<Tooltip content="Delete mapping">
										<Button
											icon="cross"
											minimal={true}
											onClick={() => this.props.onMappingRemove(record)}
										/>
									</Tooltip>
								</>
							),
							title: '',
						},
					]}
					fullWidth={true}
					noDataPlaceholder={(
						<div style={{marginBottom: 10}}>
							This feed has no mappings. Use the "Add Mapping" button below to add one.
						</div>
					)}
				/>

				<Popover
					content={(
						<Menu>
							<MenuItem
								icon={mappingTypeIcons[MappingType.ADDRESS]}
								text="Address"
								onClick={this.onAddAddressMappingClick}
							/>

							<MenuItem
								icon={mappingTypeIcons[MappingType.CONSTANT]}
								text="Constant"
								onClick={this.onAddConstantMappingClick}
							/>

							<MenuItem
								icon={mappingTypeIcons[MappingType.IMAGE]}
								text="Image"
								onClick={this.onAddImageMappingClick}
							/>

							<MenuItem
								icon={mappingTypeIcons[MappingType.MILEAGE]}
								text="Mileage"
								onClick={this.onAddMileageMappingClick}
							/>

							<MenuItem
								icon={mappingTypeIcons[MappingType.TEMPLATE]}
								text="Template"
								onClick={this.onAddTemplateMappingClick}
							/>

							<MenuItem
								icon={mappingTypeIcons[MappingType.TIMESTAMP]}
								text="Timestamp"
								onClick={this.onAddTimestampMappingClick}
							/>
						</Menu>
					)}
					position={Position.RIGHT_BOTTOM}
				>
					<Button icon="plus" loading={this.props.headersLoading}>
						Add Mapping
					</Button>
				</Popover>

				{this.props.mappings.length === 0 && (
					<>
						<Button
							icon="duplicate"
							loading={this.state.templatesLoading}
							onClick={this.onLoadTemplateButtonClick}
							style={{marginLeft: 10}}
						>
							Load Template
						</Button>

						<SelectTemplateDialog
							isOpen={this.state.showTemplateDialog}
							templates={this.state.templates}
							onDismiss={this.onTemplateDialogDismiss}
							onSave={this.onTemplateDialogSave}
						/>
					</>
				)}

				{dialog}

				{this.state.activeTranslationMapping && (
					<TranslationDialog
						mapping={this.state.activeTranslationMapping}
						onDismiss={this.onTranslationDialogDismiss}
						onSave={this.onTranslationDialogSave}
					/>
				)}
			</>
		);
	}

	private onAddAddressMappingClick = () => this.setState({
		activeType: MappingType.ADDRESS,
	});

	private onAddConstantMappingClick = () => this.setState({
		activeType: MappingType.CONSTANT,
	});

	private onAddImageMappingClick = () => this.setState({
		activeType: MappingType.IMAGE,
	});

	private onAddMileageMappingClick = () => this.setState({
		activeType: MappingType.MILEAGE,
	});

	private onAddTemplateMappingClick = () => this.setState({
		activeType: MappingType.TEMPLATE,
	});

	private onAddTimestampMappingClick = () => this.setState({
		activeType: MappingType.TIMESTAMP,
	});

	private onConfigureTranslationsButtonClick = (record: FieldMappingTypes) => this.setState({
		activeTranslationMapping: record,
	});

	private onEditClick = (record: FieldMappingTypes) => this.setState({
		activeMapping: record,
	});

	private onLoadTemplateButtonClick = () => this.setState({
		showTemplateDialog: true,
	});

	private onMappingDialogCreateMapping = (mapping: FieldMappingTypes) => {
		if (typeof mapping.translations === 'undefined')
			mapping.translations = [];

		this.props.onMappingAdd(mapping);

		this.onMappingDialogDismiss();
	};

	private onMappingDialogDismiss = () => this.setState({
		activeMapping: null,
		activeType: null,
	});

	private onMappingDialogSaveMapping = (mapping: FieldMappingTypes) => {
		this.props.onMappingEdit(mapping);

		this.onMappingDialogDismiss();
	};

	private onTemplateDialogDismiss = () => this.setState({
		showTemplateDialog: false,
	});

	private onTemplateDialogSave = (mappings: IFieldMapping[]) => {
		this.setState({
			showTemplateDialog: false,
		});

		mappings.forEach(this.onMappingDialogCreateMapping);
	};

	private onTranslationDialogDismiss = () => this.setState({
		activeTranslationMapping: null,
	});

	private onTranslationDialogSave = (
		mapping: FieldMappingTypes,
		translations: IFieldMappingTranslation[],
	) => {
		mapping.translations = translations;

		this.props.onMappingEdit(mapping);

		this.setState({
			activeTranslationMapping: null,
		});
	};
}

export const FieldMappingEditor = withApiClient(FieldMappingEditorComponent);
