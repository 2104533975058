import {Button, H2, InputGroup, Intent, Spinner} from '@blueprintjs/core';
import {Cell, MultiSelect, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {IConstraintViolations, isConstraintViolationError} from '../../../Api/Errors/ApiError';
import {IUser} from '../../../Api/Objects/User';
import {Projection} from '../../../Api/Projection';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {IToasterAware, withToaster} from '../../Contexts/ToasterContext';
import {LinkButton} from '../../Navigation/LinkButton';
import {Role} from '../../RequireRole';
import {ValidationAwareFormGroup} from '../../ValidationAwareFormGroup';

const roleNames: { [key in Role]: string } = {
	[Role.ADMIN]: 'Admin',
	[Role.USER]: 'User',
};

interface IRouteProps {
	user: string;
}

interface IUserEditorProps extends IApiClientAware, IToasterAware, RouteComponentProps<IRouteProps> {
}

interface IUserEditorState {
	archived: boolean;
	email: string;
	firstName: string;
	lastName: string;
	loading: boolean;
	redirect: boolean;
	roles: Role[];
	saving: boolean;
	violations: IConstraintViolations;
}

class UserEditorComponent extends React.PureComponent<IUserEditorProps, IUserEditorState> {
	public state: Readonly<IUserEditorState> = {
		archived: false,
		email: '',
		firstName: '',
		lastName: '',
		loading: true,
		redirect: false,
		roles: [],
		saving: false,
		violations: null,
	};

	public componentDidMount(): void {
		const idParam = this.props.match.params.user;

		if (idParam === 'new') {
			this.setState({
				loading: false,
			});

			return;
		}

		this.props.client.users.get(parseInt(idParam, 10), {
			archived: true,
			email: true,
			firstName: true,
			lastName: true,
			roles: true,
		}).then(user => this.setState({
			archived: user.archived,
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			loading: false,
			roles: user.roles,
		}));
	}

	public render(): React.ReactNode {
		if (this.state.loading)
			return <Spinner intent={Intent.PRIMARY} />;
		else if (this.state.redirect)
			return <Redirect to="/edit/users" />;

		const name = `${this.state.firstName} ${this.state.lastName}`.trim();

		return (
			<>
				<H2>{name.length ? name : <em>No Name</em>}</H2>

				<form>
					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup
								label="First Name"
								labelFor="firstName"
								violations={this.state.violations}
							>
								<InputGroup
									name="firstName"
									onChange={this.onFirstNameChange}
									value={this.state.firstName}
								/>
							</ValidationAwareFormGroup>
						</Cell>

						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Last Name"
								labelFor="lastName"
								violations={this.state.violations}
							>
								<InputGroup
									name="lastName"
									onChange={this.onLastNameChange}
									value={this.state.lastName}
								/>
							</ValidationAwareFormGroup>
						</Cell>
					</Row>

					<Row>
						<Cell size={6}>
							<ValidationAwareFormGroup
								label="Email Address"
								labelFor="email"
								violations={this.state.violations}
							>
								<InputGroup name="email" onChange={this.onEmailChange} value={this.state.email} />
							</ValidationAwareFormGroup>
						</Cell>

						<Cell size={6}>
							<ValidationAwareFormGroup label="Roles" labelFor="roles" violations={this.state.violations}>
								<MultiSelect
									items={[Role.ADMIN, Role.USER]}
									itemTextRenderer={this.renderRoleValue}
									onClear={this.onRolesClear}
									onItemDeselect={this.onRoleDeselect}
									onItemSelect={this.onRoleSelect}
									popoverProps={{
										targetClassName: 'full-width',
									}}
									selected={this.state.roles}
								/>
							</ValidationAwareFormGroup>
						</Cell>
					</Row>

					<Row align="end">
						<Cell size={1}>
							<LinkButton to="/edit/users" buttonProps={{fill: true, loading: this.state.saving}}>
								Cancel
							</LinkButton>
						</Cell>

						<Cell size={1}>
							<Button fill={true} intent={Intent.PRIMARY} loading={this.state.saving} onClick={this.save}>
								Save
							</Button>
						</Cell>
					</Row>
				</form>
			</>
		);
	}

	private renderRoleValue = (role: Role) => roleNames[role];

	private onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		email: event.currentTarget.value,
	});

	private onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		firstName: event.currentTarget.value,
	});

	private onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		lastName: event.currentTarget.value,
	});

	private onRolesClear = () => this.setState({
		roles: [],
	});

	private onRoleDeselect = (removed: Role) => this.setState({
		roles: this.state.roles.filter(role => role !== removed),
	});

	private onRoleSelect = (role: Role) => this.setState({
		roles: [...this.state.roles, role],
	});

	private save = (event?: React.SyntheticEvent<any>) => {
		if (event)
			event.preventDefault();

		if (this.state.saving)
			return;

		this.setState({
			saving: true,
		});

		const payload: IUser = {
			email: this.state.email.trim(),
			firstName: this.state.firstName.trim(),
			lastName: this.state.lastName.trim(),
			roles: this.state.roles,
		};

		const projection: Projection = {
			firstName: true,
			lastName: true,
		};

		const idParam = this.props.match.params.user;
		let promise: Promise<IUser>;

		if (idParam === 'new') {
			payload.activationUrl = `${window.location.protocol}//${window.location.host}/activate/:code`;

			promise = this.props.client.users.create(payload, projection);
		} else
			promise = this.props.client.users.update(parseInt(idParam, 10), payload, projection);

		promise.then(user => {
			this.props.toaster.show({
				intent: Intent.SUCCESS,
				message: `${user.firstName} ${user.lastName} ${idParam === 'new' ? 'created' : 'saved'} successfully.`,
			});

			this.setState({
				redirect: true,
			});
		}).catch((error: Error) => {
			this.props.toaster.show({
				intent: Intent.DANGER,
				message: error.message,
			});

			this.setState({
				saving: false,
			});

			if (isConstraintViolationError(error)) {
				this.setState({
					violations: error.context.violations,
				});
			}
		});
	};
}

export const UserEditor = withApiClient(withToaster(withRouter(UserEditorComponent)));
