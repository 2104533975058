import {Button, Classes, Dialog, FormGroup, Intent, MenuItem} from '@blueprintjs/core';
import {IItemRendererProps, Select} from '@blueprintjs/select';
import * as React from 'react';
import {FieldMappingTypes, IFieldMapping} from '../../../Api/Objects/Feed';
import {IFeedMappingTemplate} from '../../../Api/Objects/FeedMappingTemplate';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {IThemeAware, withTheme} from '../../Contexts/ThemeContext';
import {Theme} from '../../Theme/Theme';

type FeedMappingTemplate = Pick<IFeedMappingTemplate, 'id' | 'name'>;

interface ISelectTemplateDialogProps extends IApiClientAware, IThemeAware {
	isOpen: boolean;
	templates: FeedMappingTemplate[];
	onDismiss: () => void;
	onSave: (mappings: IFieldMapping[]) => void;
}

interface ISelectTemplateDialogState {
	processing: boolean;
	selected: FeedMappingTemplate;
}

class SelectTemplateDialogComponent
	extends React.PureComponent<ISelectTemplateDialogProps, ISelectTemplateDialogState> {
	public state: Readonly<ISelectTemplateDialogState> = {
		processing: false,
		selected: null,
	};

	public render(): React.ReactNode {
		const body = this.props.templates.length === 0 ? (
			<p>
				No templates available.
			</p>
		) : (
			<FormGroup label="Template">
				<Select
					items={this.props.templates}
					itemRenderer={this.renderTemplateItem}
					onItemSelect={this.onTemplateSelect}
					popoverProps={{targetClassName: 'full-width'}}
				>
					<Button
						alignText="left"
						fill={true}
						rightIcon="caret-down"
					>
						{this.state.selected ? this.state.selected.name : 'Select a template...'}
					</Button>
				</Select>
			</FormGroup>
		);

		return (
			<Dialog
				canEscapeKeyClose={!this.state.processing}
				canOutsideClickClose={!this.state.processing}
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isCloseButtonShown={!this.state.processing}
				isOpen={this.props.isOpen}
				onClose={this.onClose}
				title="Load Template"
			>
				<div className={Classes.DIALOG_BODY}>
					{body}

					{this.state.processing && (
						<div style={{marginTop: 10}}>
							Your template is being loaded, please wait.
						</div>
					)}
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button disabled={this.state.processing} onClick={this.onClose}>
							Cancel
						</Button>

						<Button
							disabled={this.state.selected === null}
							intent={Intent.PRIMARY}
							loading={this.state.processing}
							onClick={this.onSave}
						>
							Continue
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private renderTemplateItem = (item: FeedMappingTemplate, props: IItemRendererProps) => {
		if (!props.modifiers.matchesPredicate)
			return null;

		return (
			<MenuItem
				active={props.modifiers.active}
				key={item.id}
				onClick={props.handleClick}
				text={item.name}
			/>
		);
	};

	private onClose = () => {
		if (this.state.processing)
			return;

		this.props.onDismiss();
	};

	private onSave = () => {
		if (this.state.processing)
			return;

		this.setState({
			processing: true,
		});

		this.props.client.templates.get(this.state.selected).then(template => {
			this.setState({
				processing: false,
				selected: null,
			});

			this.props.onSave(template.fields.map(field => ({
				...field.arguments,
				targetField: field.targetField,
				translations: field.translations,
				type: field.type,
			} as FieldMappingTypes)));
		});
	};

	private onTemplateSelect = (selected: FeedMappingTemplate) => this.setState({
		selected,
	});
}

export const SelectTemplateDialog = withTheme(withApiClient(SelectTemplateDialogComponent));
