import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {ICatalog} from '../../../Api/Objects/Catalog';
import {IApiClientAware, withApiClient} from '../../Contexts/ApiClientContext';
import {LinkButton} from '../../Navigation/LinkButton';
import {BreadcrumbItems, EditorBreadcrumbs} from '../EditorBreadcrumbs';
import {createEntityFilter, createEntitySorter, EntityList} from '../EntityList';

type Catalog = Pick<ICatalog, 'id' | 'createdDate' | 'name' | 'feeds'>;

const sorter = createEntitySorter<Catalog>('name');
const filterEntityOnName = createEntityFilter<Catalog>('name');

interface IRouteProps {
	account: string;
}

interface ICatalogListProps extends IApiClientAware, RouteComponentProps<IRouteProps> {
}

const CatalogListComponent: React.FC<ICatalogListProps> = props => (
	<>
		<EditorBreadcrumbs
			items={[
				BreadcrumbItems.accounts(),
				BreadcrumbItems.catalogs(props.match.params.account),
			]}
		/>

		<EntityList
			basePath={`/edit/accounts/${props.match.params.account}/catalogs`}
			deleteToastMessage={target => `${target.name} has been deleted successfully.`}
			extraControls={(
				<LinkButton to={`/edit/accounts/${props.match.params.account}`} buttonProps={{icon: 'edit'}}>
					Edit Account
				</LinkButton>
			)}
			projection={{
				createdDate: true,
				'feeds.id': true,
				name: true,
			}}
			provider={props.client.catalogs}
			query={{
				'account.id': props.match.params.account,
			}}
			sorter={sorter}
			tableColumns={[
				{
					dataIndex: 'name',
					onFilter: filterEntityOnName,
					title: 'Name',
				},
				{
					render: record => record.createdDate.toLocaleString(),
					title: 'Created Date',
				},
				{
					render: record => (
						<Link to={`/edit/accounts/${props.match.params.account}/catalogs/${record.id}/feeds`}>
							{record.feeds.length} Feed{record.feeds.length !== 1 ? 's' : ''}
						</Link>
					),
					title: 'Product Feeds',
				},
			]}
			tableNoDataPlaceholder={<span>No catalogs found.</span>}
			title="Catalogs"
		/>
	</>
);

export const CatalogList = withRouter(withApiClient(CatalogListComponent));
