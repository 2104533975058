import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from '@blueprintjs/core';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {IAddressFieldMapping, MappingType} from '../../../../Api/Objects/Feed';
import {IThemeAware, withTheme} from '../../../Contexts/ThemeContext';
import {StateSelect} from '../../../StateSelect';
import {Theme} from '../../../Theme/Theme';
import {FeedFieldsSelect} from '../FeedFieldsSelect';
import {IMappingDialogProps} from '../FieldMappingEditor';

interface IAddressMappingDialogProps extends IMappingDialogProps<IAddressFieldMapping>, IThemeAware {
}

interface IAddressMappingDialogState {
	city: string;
	postalCode: string;
	region: string;
	street: string;
	targetField: string;
}

class AddressMappingDialogComponent
	extends React.PureComponent<IAddressMappingDialogProps, IAddressMappingDialogState> {
	public constructor(props: IAddressMappingDialogProps) {
		super(props);

		const mapping: IAddressFieldMapping = props.mapping || {
			type: MappingType.ADDRESS,
		};

		this.state = {
			city: mapping.city || '',
			postalCode: mapping.postalCode || '',
			region: mapping.region || '',
			street: mapping.street || '',
			targetField: mapping.targetField || '',
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={true}
				onClose={this.props.onDismiss}
				title={`${this.props.mapping ? 'Edit' : 'Add'} Address Mapping`}
			>
				<div className={Classes.DIALOG_BODY}>
					<form onSubmit={this.save}>
						<FormGroup label="Target Field">
							<FeedFieldsSelect
								omit={this.props.omitMappingFields}
								onItemSelect={this.onTargetFieldSelect}
								selected={this.state.targetField}
							/>
						</FormGroup>

						<Row>
							<Cell size={6}>
								<FormGroup label="Street Name" labelFor="street">
									<InputGroup
										name="street"
										onChange={this.onStreetChange}
										value={this.state.street}
									/>
								</FormGroup>
							</Cell>

							<Cell size={6}>
								<FormGroup label="City" labelFor="city">
									<InputGroup name="city" onChange={this.onCityChange} value={this.state.city} />
								</FormGroup>
							</Cell>
						</Row>

						<Row>
							<Cell size={6}>
								<FormGroup label="Postal Code" labelFor="postalCode">
									<InputGroup
										name="postalCode"
										onChange={this.onPostalCodeChange}
										value={this.state.postalCode}
									/>
								</FormGroup>
							</Cell>

							<Cell size={6}>
								<FormGroup label="State">
									<StateSelect onItemSelect={this.onRegionSelect} selected={this.state.region} />
								</FormGroup>
							</Cell>
						</Row>
					</form>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={this.props.onDismiss}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} onClick={this.save}>
							Save
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private onCityChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		city: event.currentTarget.value,
	});

	private onPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		postalCode: event.currentTarget.value,
	});

	private onRegionSelect = (region: string) => this.setState({
		region,
	});

	private onStreetChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		street: event.currentTarget.value,
	});

	private onTargetFieldSelect = (targetField: string) => this.setState({
		targetField,
	});

	private save = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		const mapping = this.props.mapping || {
			type: MappingType.ADDRESS,
		};

		mapping.city = this.state.city;
		mapping.country = 'USA';
		mapping.postalCode = this.state.postalCode;
		mapping.region = this.state.region;
		mapping.street = this.state.street;
		mapping.targetField = this.state.targetField;

		this.props.mapping ? this.props.onSave(mapping) : this.props.onCreate(mapping);
	};
}

export const AddressMappingDialog = withTheme(AddressMappingDialogComponent);
