import {ApiClient} from '../ApiClient';
import {AbstractApiClientModule} from '../Module';
import {IEntity} from './Entity';
import {IFeed} from './Feed';

export interface ICatalog extends IEntity {
	account?: number;
	archived?: boolean;
	createdDate?: Date;
	defaultImageUrl?: string;
	facebookId?: number;
	feeds?: IFeed[];
	name?: string;
}

export class CatalogApiClientModule extends AbstractApiClientModule<ICatalog> {
	public constructor(client: ApiClient) {
		super(client, '/catalogs');
	}

	protected denormalize(entity: ICatalog): ICatalog {
		if (typeof entity.createdDate === 'string')
			entity.createdDate = new Date(entity.createdDate);

		return entity;
	}
}
