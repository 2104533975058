import {InputGroup} from '@blueprintjs/core';
import {Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';

interface IFlexibleSourceHeaderSelectProps {
	headers: string[];
	onChange: (value: string) => void;
	selected: string;
}

export const FlexibleSourceHeaderSelect: React.FC<IFlexibleSourceHeaderSelectProps> = props => {
	let child: React.ReactNode;

	if (props.headers.length === 0) {
		child = (
			<InputGroup
				name="sourceField"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event.currentTarget.value)}
				value={props.selected}
			/>
		);
	} else {
		child = (
			<Select
				items={props.headers}
				noItemSelected="Select a field..."
				onItemSelect={props.onChange}
				popoverProps={{
					targetClassName: 'full-width',
				}}
				selected={props.selected}
			/>
		);
	}

	return (
		<>
			{child}
		</>
	);
};
