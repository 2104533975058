import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from '@blueprintjs/core';
import * as React from 'react';
import {Redirect, RouteComponentProps} from 'react-router';
import {IApiClientAware, withApiClient} from '../Contexts/ApiClientContext';
import {IThemeAware, withTheme} from '../Contexts/ThemeContext';
import {IToasterAware, withToaster} from '../Contexts/ToasterContext';
import {Theme} from '../Theme/Theme';
import './Login.scss';

interface ILoginProps extends RouteComponentProps<{}>, IToasterAware, IApiClientAware, IThemeAware {
}

interface ILoginState {
	password: string;
	passwordResetEmail: string;
	processing: boolean;
	processingPasswordReset: boolean;
	redirect: boolean;
	showPasswordResetDialog: boolean;
	username: string;
}

class LoginComponent extends React.Component<ILoginProps, ILoginState> {
	public state: Readonly<ILoginState> = {
		password: '',
		passwordResetEmail: '',
		processing: false,
		processingPasswordReset: false,
		redirect: false,
		showPasswordResetDialog: false,
		username: '',
	};

	public render(): JSX.Element {
		if (this.state.redirect || this.props.client.isAuthenticated()) {
			const {from} = this.props.location.state || {from: {pathname: '/'}};

			if (from.pathname === '/login') {
				from.pathname = '/';
			}

			return <Redirect to={from} />;
		}

		return (
			<div id="login-component" className="no-navbar">
				<form onSubmit={this.onFormSubmit}>
					<FormGroup label="Email Address" labelFor="email-address">
						<InputGroup id="email-address" onChange={this.onUsernameInputChange} />
					</FormGroup>

					<FormGroup label="Password" labelFor="password">
						<InputGroup type="password" id="password" onChange={this.onPasswordInputChange} />
					</FormGroup>

					<Button type="submit" loading={this.state.processing}>
						Sign In
					</Button>

					<span
						className={`${Classes.TEXT_MUTED} password-reset-link`}
						onClick={this.onPasswordResetButtonClick}
					>
						Forgot your password?
					</span>
				</form>

				<Dialog
					canEscapeKeyClose={!this.state.processingPasswordReset}
					canOutsideClickClose={!this.state.processingPasswordReset}
					className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
					isCloseButtonShown={!this.state.processingPasswordReset}
					isOpen={this.state.showPasswordResetDialog}
					onClose={this.onPasswordResetDialogDismiss}
					title="Reset Your Password"
				>
					<div className={Classes.DIALOG_BODY}>
						<p>
							To reset your password, please enter your email address in the form below. If an account
							with that email address exists, you should receive an email with instructions shortly.
						</p>

						<form onSubmit={this.onPasswordResetDialogSubmit}>
							<FormGroup label="Email Address" labelFor="passwordResetEmail">
								<InputGroup
									name="passwordResetEmail"
									onChange={this.onPasswordResetEmailChange}
									value={this.state.passwordResetEmail}
								/>
							</FormGroup>
						</form>
					</div>

					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								disabled={this.state.processingPasswordReset}
								onClick={this.onPasswordResetDialogDismiss}
							>
								Cancel
							</Button>

							<Button
								loading={this.state.processingPasswordReset}
								intent={Intent.PRIMARY}
								onClick={this.onPasswordResetDialogSubmit}
							>
								Submit
							</Button>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}

	private onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (this.state.processing) {
			return;
		}

		this.setState({
			processing: true,
		});

		this.props.client.login(this.state.username, this.state.password)
			.then(() => this.setState({
				redirect: true,
			}))
			.catch((error: Error) => {
				this.setState({
					processing: false,
				});

				this.props.toaster.show({
					intent: Intent.DANGER,
					message: error.message || 'An unknown error has occurred. Please refresh the page, then try again.',
					timeout: 5000,
				});
			});
	};

	private onUsernameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		username: event.target.value,
	});

	private onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		password: event.target.value,
	});

	private onPasswordResetButtonClick = () => this.setState({
		passwordResetEmail: '',
		showPasswordResetDialog: true,
	});

	private onPasswordResetDialogDismiss = () => {
		if (this.state.processingPasswordReset)
			return;

		this.setState({
			showPasswordResetDialog: false,
		});
	};

	private onPasswordResetDialogSubmit = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		if (this.state.processingPasswordReset)
			return;

		this.setState({
			processingPasswordReset: true,
		});

		this.props.client.users.sendPasswordResetCode(this.state.passwordResetEmail)
			.then(() => {
				this.props.toaster.show({
					intent: Intent.SUCCESS,
					message: 'Reset request sent successfully. Check your inbox for instructions.',
				});

				this.setState({
					showPasswordResetDialog: false,
				});
			})
			.catch((error: Error) => {
				this.props.toaster.show({
					intent: Intent.DANGER,
					message: error.message,
				});
			})
			.finally(() => this.setState({
				processingPasswordReset: false,
			}));
	};

	private onPasswordResetEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({
		passwordResetEmail: event.currentTarget.value,
	});
}

export const Login = withTheme(withApiClient(withToaster(LoginComponent)));
