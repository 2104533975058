import {Alignment, Button, Navbar, NavbarDivider, NavbarGroup} from '@blueprintjs/core';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {LogoutHandler} from '../Auth/LogoutHandler';
import {RequireRole, Role} from '../RequireRole';
import {ThemeSwitcher} from '../Theme/ThemeSwitcher';
import {LinkButton} from './LinkButton';

export const Navigation: React.FC<{}> = () => (
	<Navbar fixedToTop={true}>
		<NavbarGroup align={Alignment.LEFT}>
			<Link to="/" className="plain-link">
				Dynamic Ads
			</Link>

			<NavbarDivider />

			<LinkButton to="/edit/accounts" buttonProps={{minimal: true}}>
				Accounts
			</LinkButton>

			<LinkButton to="/edit/templates" buttonProps={{minimal: true}}>
				Templates
			</LinkButton>

			<RequireRole role={Role.ADMIN}>
				<LinkButton to="/edit/users" buttonProps={{minimal: true}}>
					Users
				</LinkButton>
			</RequireRole>
		</NavbarGroup>

		<NavbarGroup align={Alignment.RIGHT}>
			<ThemeSwitcher />

			<NavbarDivider />

			<LogoutHandler>
				<Button minimal={true}>
					Logout
				</Button>
			</LogoutHandler>
		</NavbarGroup>
	</Navbar>
);
