import * as React from 'react';
import {Redirect, Route} from 'react-router';
import './App.scss';
import {Editors} from './Editors';
import {Navigation} from './Navigation/Navigation';
import {Switch} from './Navigation/Switch';

export const App: React.FC<{}> = () => (
	<>
		<Navigation />

		<div id="app-content">
			<Switch>
				<Route path="/" exact={true} children={<Redirect to="/edit/accounts" />} />
				<Route path="/edit" component={Editors} />
			</Switch>
		</div>
	</>
);
