import {ApiClient} from '../ApiClient';
import {AbstractApiClientModule} from '../Module';
import {ICatalog} from './Catalog';
import {IEntity} from './Entity';
import {IPixel} from './Pixel';

export interface IAccount extends IEntity {
	name?: string;
	createdDate?: Date;
	archived?: boolean;
	facebookId?: string;
	catalogs?: Array<Pick<ICatalog, 'id' | 'name' | 'createdDate'>>;
	pixels?: Array<Pick<IPixel, 'id' | 'name'>>;
}

export class AccountApiClientModule extends AbstractApiClientModule<IAccount> {
	public constructor(client: ApiClient) {
		super(client, '/accounts');
	}

	protected denormalize(object: IAccount): IAccount {
		if (typeof object.createdDate === 'string')
			object.createdDate = new Date(object.createdDate);

		return object;
	}
}
