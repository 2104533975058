import * as React from 'react';
import {Subtract} from 'utility-types';
import {Theme} from '../Theme/Theme';

export const ThemeContext = React.createContext(Theme.DARK);

export interface IThemeAware {
	theme: Theme;
}

export const withTheme = <P extends IThemeAware>(Component: React.ComponentType<P>):
	React.ComponentType<Subtract<P, IThemeAware>> => props => (
		<ThemeContext.Consumer>
			{theme => <Component theme={theme} {...props} />}
		</ThemeContext.Consumer>
	);

export const ThemeChangerContext = React.createContext((theme: Theme): void => {
	throw new Error('ThemeChangerContext used without a parent provider');
});

export interface IThemeChangerAware {
	onThemeChange: (theme: Theme) => void;
}

export const withThemeChanger = <P extends IThemeChangerAware>(Component: React.ComponentType<P>):
	React.ComponentType<Subtract<P, IThemeChangerAware>> => props => (
		<ThemeChangerContext.Consumer>
			{onThemeChange => <Component onThemeChange={onThemeChange} {...props} />}
		</ThemeChangerContext.Consumer>
	);
