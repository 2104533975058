import {Button, Classes, Dialog, FormGroup, Intent, MenuItem} from '@blueprintjs/core';
import {IItemRendererProps, Select} from '@blueprintjs/select';
import {Cell, Row} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {IMileageFieldMapping, MappingType, unitToName} from '../../../../Api/Objects/Feed';
import {ucfirst} from '../../../../Utility/string';
import {IThemeAware, withTheme} from '../../../Contexts/ThemeContext';
import {Theme} from '../../../Theme/Theme';
import {FeedFieldsSelect} from '../FeedFieldsSelect';
import {IMappingDialogProps} from '../FieldMappingEditor';
import {FlexibleSourceHeaderSelect} from './FlexibleSourceHeaderSelect';

interface IMileageMappingDialogProps extends IMappingDialogProps<IMileageFieldMapping>, IThemeAware {
}

interface IMileageMappingDialogState {
	sourceField: string;
	targetField: string;
	unit: string;
}

class MileageMappingDialogComponent
	extends React.PureComponent<IMileageMappingDialogProps, IMileageMappingDialogState> {
	public constructor(props: IMileageMappingDialogProps) {
		super(props);

		const mapping: IMileageFieldMapping = props.mapping || {
			type: MappingType.MILEAGE,
		};

		this.state = {
			sourceField: mapping.sourceField || '',
			targetField: mapping.targetField || '',
			unit: mapping.unit || 'MI',
		};
	}

	public render(): React.ReactNode {
		return (
			<Dialog
				className={this.props.theme === Theme.DARK ? Classes.DARK : ''}
				isOpen={true}
				onClose={this.props.onDismiss}
				title={`${this.props.mapping ? 'Edit' : 'Add'} Mileage Mapping`}
			>
				<div className={Classes.DIALOG_BODY}>
					<form onSubmit={this.save}>
						<FormGroup label="Target Field">
							<FeedFieldsSelect
								omit={this.props.omitMappingFields}
								onItemSelect={this.onTargetFieldSelect}
								selected={this.state.targetField}
							/>
						</FormGroup>

						<Row>
							<Cell size={8}>
								<FormGroup label="Source Field" labelFor="sourceField">
									<FlexibleSourceHeaderSelect
										headers={this.props.headers}
										onChange={this.onSourceFieldChange}
										selected={this.state.sourceField}
									/>
								</FormGroup>
							</Cell>

							<Cell size={4}>
								<FormGroup label="Unit">
									<Select
										filterable={false}
										items={['MI', 'KM']}
										itemRenderer={this.renderUnitItem}
										onItemSelect={this.onUnitSelect}
										popoverProps={{targetClassName: 'full-width'}}
									>
										<Button
											alignText="left"
											fill={true}
											rightIcon="caret-down"
											text={ucfirst(unitToName(this.state.unit))}
										/>
									</Select>
								</FormGroup>
							</Cell>
						</Row>
					</form>
				</div>

				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={this.props.onDismiss}>
							Cancel
						</Button>

						<Button intent={Intent.PRIMARY} onClick={this.save}>
							Save
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}

	private renderUnitItem = (item: string, props: IItemRendererProps) => {
		if (!props.modifiers.matchesPredicate)
			return null;

		return (
			<MenuItem
				active={props.modifiers.active}
				key={item}
				onClick={props.handleClick}
				text={ucfirst(unitToName(item))}
			/>
		);
	};

	private onSourceFieldChange = (sourceField: string) => this.setState({
		sourceField,
	});

	private onTargetFieldSelect = (targetField: string) => this.setState({
		targetField,
	});

	private onUnitSelect = (unit: string) => this.setState({
		unit,
	});

	private save = (event: React.SyntheticEvent<any>) => {
		event.preventDefault();

		const mapping = this.props.mapping || {
			type: MappingType.MILEAGE,
		};

		mapping.sourceField = this.state.sourceField;
		mapping.targetField = this.state.targetField;
		mapping.unit = this.state.unit;

		this.props.mapping ? this.props.onSave(mapping) : this.props.onCreate(mapping);
	};
}

export const MileageMappingDialog = withTheme(MileageMappingDialogComponent);
