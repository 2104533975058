import * as React from 'react';
import {Route} from 'react-router';
import {Switch} from '../Navigation/Switch';
import {Accounts} from './Accounts';
import {Templates} from './Templates';
import {Users} from './Users';

export const Editors: React.FC<{}> = () => (
	<div className="content-container">
		<Switch>
			<Route path="/edit/accounts" component={Accounts} />
			<Route path="/edit/templates" component={Templates} />
			<Route path="/edit/users" component={Users} />
		</Switch>
	</div>
);
