import {Button, IconName, Tooltip} from '@blueprintjs/core';
import * as React from 'react';
import {IThemeAware, IThemeChangerAware, withTheme, withThemeChanger} from '../Contexts/ThemeContext';
import {Theme, ThemeSequence} from './Theme';

const themeIcons: {[key in Theme]: IconName} = {
	[Theme.DARK]: 'moon',
	[Theme.LIGHT]: 'flash',
};

interface IThemeSwitcherProps extends IThemeAware, IThemeChangerAware {
}

interface IThemeSwitcherState {
	theme: Theme;
}

class ThemeSwitcherComponent extends React.Component<IThemeSwitcherProps, IThemeSwitcherState> {
	public static getDerivedStateFromProps(nextProps: IThemeSwitcherProps): Partial<IThemeSwitcherState> {
		return {
			theme: nextProps.theme,
		};
	}

	public constructor(props: IThemeSwitcherProps) {
		super(props);

		this.state = {
			theme: props.theme,
		};
	}

	public render(): JSX.Element {
		const {theme} = this.props;
		const nextTheme = ThemeSequence[theme];

		return (
			<Tooltip content={`Change to the ${nextTheme.toLowerCase()} theme`} hoverOpenDelay={650}>
				<Button
					minimal={true}
					icon={themeIcons[nextTheme]}
					onClick={this.onThemeButtonClick}
				/>
			</Tooltip>
		);
	}

	private onThemeButtonClick = () => {
		this.props.onThemeChange(ThemeSequence[this.props.theme]);
	};
}

export const ThemeSwitcher = withTheme(withThemeChanger(ThemeSwitcherComponent));
