import {Button, IButtonProps} from '@blueprintjs/core';
import * as React from 'react';
import {Link, LinkProps} from 'react-router-dom';
import {Omit} from 'utility-types';

interface ILinkButtonProps {
	/**
	 * The path that the button should link to.
	 */
	to: string;

	/**
	 * A dictionary of props to pass down to the wrapped {@see Button} component.
	 */
	buttonProps?: IButtonProps;

	/**
	 * A dictionary of props to pass down to the wrapped {@see Link} component.
	 */
	linkProps?: Omit<LinkProps, 'to' | 'style'>;

	/**
	 * A dictionary of CSS styles to apply to the component container.
	 */
	style?: React.CSSProperties;
}

export const LinkButton: React.FC<ILinkButtonProps> = props => (
	<Link to={props.to} {...props.linkProps} className={props.linkProps.className || 'plain-link'} style={props.style}>
		<Button {...props.buttonProps}>
			{props.children}
		</Button>
	</Link>
);

LinkButton.defaultProps = {
	buttonProps: {},
	linkProps: {},
};
