import {Select} from '@dbstudios/blueprintjs-components';
import * as React from 'react';
import {feedFieldLabels, feedFields, requiredFeedFields} from '../../../Facebook/FeedFields';

const renderItemText = (item: string) => {
	const text = feedFieldLabels[item] || item;

	if (requiredFeedFields.indexOf(item) !== -1)
		return <strong>{text}*</strong>;

	return <span>{text}</span>;
};

const filterItemList = (query: string, items: string[]) => {
	query = query.toLowerCase();

	return items.filter(item => {
		return item.toLowerCase().indexOf(query) > -1 || feedFieldLabels[item].toLowerCase().indexOf(query) > -1;
	});
};

interface IFeedFieldsSelectProps {
	onItemSelect: (item: string) => void;
	selected: string;

	omit?: string[];
}

export const FeedFieldsSelect: React.FC<IFeedFieldsSelectProps> = props => (
	<Select
		items={feedFields}
		itemListPredicate={filterItemList}
		itemTextRenderer={renderItemText}
		noItemSelected="Select a field..."
		onItemSelect={props.onItemSelect}
		popoverProps={{
			targetClassName: 'full-width',
		}}
		selected={props.selected}
		omit={props.omit}
	/>
);
